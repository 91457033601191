import React from "react"
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import SubmitBtn from './submit_btn'
import CancelBtn from './cancel_btn'
import actions from '../actions'

class UploadCoverStep extends React.PureComponent {
  render() {
    const { audio, coverFile, loading, canceling } = this.props.uploader;
    if (!audio) return;
    const isSubmitDisabled = false // allow to skip cover upload
    return (
      <div>
        <Upload.Dragger
          beforeUpload={this.onSelectCoverFile}
          accept=".png, .jpeg, .jpg"
          onRemove={this.onRemoveCoverFile}
          fileList={coverFile ? [coverFile] : []}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{audio.fullTitle}</p>
          <p className="ant-upload-text">Upload cover image with extension .png, .jpg</p>
          <p className="ant-upload-hint">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>

        <div className="mb-steps-action">
          <SubmitBtn disabled={isSubmitDisabled} onClick={this.onUploadCoverFile} loading={loading} />
          <CancelBtn loading={loading} canceling={canceling} onClick={actions.cancel} />
        </div>
      </div>
    )
  }

  onSelectCoverFile = (file) => {
    //console.log("onSelectCoverFile: ", file)
    //TODO: validate by extension and size
    if (!file) { return false; }
    actions.selectCoverFile(file)
    return false; // important to return false
  }

  onRemoveCoverFile = () => {
    //console.log("onRemoveCoverFile: ")
    actions.selectCoverFile(null)
  }

  onUploadCoverFile = () => {
    //console.log("onUploadCoverFile: ")

    const {coverFile, audio} = this.props.uploader;

    if (coverFile == null) {
      actions.uploadCoverSkip()
      return;
    }

    const data = new FormData();
    data.append('audio_id', audio.id)
    data.append('file', coverFile)
    actions.uploadCover(data)
  }
}

export default UploadCoverStep;
