import React from "react"

class Video extends React.PureComponent {
  componentDidMount() {
    document.lazyLoadInstance.update();
  }

  render() {
    return (
      <div className="mb-embed-responsive">
        <iframe
          className="lazy mb-embed-responsive-item"
          data-src={`https://www.youtube.com/embed/${this.props.videoId}`}
          frameBorder="0"
          allowFullScreen>
        </iframe>
      </div>
    );
  }
}

export default Video;
