import React from 'react'
import ReactDOM from 'react-dom'

import { Layout, Typography, Row, Col, Avatar, Badge, Button, Dropdown, Popconfirm } from 'antd'

import { AntDesignOutlined, UserOutlined, ShoppingCartOutlined, EditOutlined, LogoutOutlined, PlusOutlined } from '@ant-design/icons';
import { HiDotsVertical } from "react-icons/hi";
import { IoKeyOutline } from "react-icons/io5";
import actions from '../actions'

import { Navigate, Link } from "react-router-dom";
import RouterParams from "../helpers/router_params"
import dayjs from 'dayjs'
import AppContext from '../components/app_context'

class Profile extends React.PureComponent {
  static contextType = AppContext;

  render() {
    if (!this.context.loginCompleted) { return <Navigate to="/login" replace={true} /> }
    const { user } = this.context
    return (
      <Layout.Content id="mb-profile" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Typography.Title level={4} className="mb-left">My profile</Typography.Title>

              <Avatar
                size={128}
                icon={<UserOutlined />}
                src={user.avatarUrl}
              />
              <Row>
                <Col sm={24}>
                  {this.renderHeader()}
                  <div className="mb-reg-date">Registered at {dayjs(user.createdAt).format('DD MMM YYYY')}</div>

                  <div className="mb-details">
                    { user.earlyAdopter ? <Badge className="mb-sub-promo" count="Early Adopter" title="Congrats! You have received 6 months subscription for free" /> : null }
                    { user.subscribed ? this.renderSubscribed(user) : this.renderNotSubscribed(user) }
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    )
  }

  renderSubscribed(user) {
    return (
      <div>
        <div>Editor PRO version <Badge className="mb-sub mb-sub-yes" count="Yes" title="All PRO features are enabled for MBOY Editor now!" /></div>
        <div>Subscription ends at {dayjs(user.subscriptionEndsAt).format('DD MMM YYYY, HH:mm')}</div>
        <div className="mb-actions">
          {this.renderProBtn("Extend PRO")}
        </div>
      </div>
    )
  }

  renderNotSubscribed(user) {
    return (
      <div>
        <div>
          Editor PRO version <Badge className="mb-sub mb-sub-no" count="No" title="MBOY Editor PRO features are not enabled yet" />
          {this.renderProBtn()}
        </div>
      </div>
    )
  }

  renderProBtn(label="Buy PRO") {
    return (
      <Button
        type="primary"
        shape="round"
        size="large"
        onClick={() => this.props.routerParams.navigate('/?header=buy-pro') }
        icon={<ShoppingCartOutlined />}
        >
        {label}
      </Button>
    )
  }

  buildMenuItems(user) {
    const logoutLink = <Popconfirm
      title="Are you sure to log out?"
      onConfirm={() => actions.logout() }
      okText="Yes, log out"
      cancelText="No"
    >
      <span>Log out</span>
    </Popconfirm>

    return [
      {icon: <EditOutlined />, label: <Link to="/profile/edit">Edit profile</Link>},
      {icon: <IoKeyOutline />, label: <Link to="/profile/secure">Change password</Link>},
      {icon: <PlusOutlined />, label: <Link to={`/library?user_id=${user.id}`}>My uploads</Link>},
      {icon: <LogoutOutlined />, label: logoutLink }
    ];
  }

  renderHeader(){
    const { user } = this.context
    const kebab = <Dropdown menu={{items: this.buildMenuItems(user)}} placement="bottom" arrow={false} trigger="click">
                    <Button className="mb-kebab-btn" shape="circle" icon={<HiDotsVertical style={{fontSize: 16}} />} />
                  </Dropdown>

    if (user.nickname != null) {
      return (
        <div>
          <div className="mb-nickname">{user.nickname}{kebab}</div>
          <div className="mb-email">{user.email}</div>
        </div>
      )
    }
    else {
      return (
        <div>
          <div className="mb-email">{user.email}{kebab}</div>
        </div>
      )
    }
  }

}

export default RouterParams(Profile);
