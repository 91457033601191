import Reflux from 'reflux'
import actions from './actions'
import update from 'immutability-helper';
import { message } from 'antd';
import { redirect } from "react-router-dom";

class Store extends Reflux.Store {
  constructor(){
    super()
    this.listenToMany(actions)
    this.state = {
      listing: this.buildListing()
    }
  }

  buildListing() {
    return {
      audios: [],
      loading: false,
      hasMore: false,
      filter: {
        userId: null,
        artist: null,
        title: null,
        operator: null,
        page: 1,
      },
      search: {
        artists: [],
        titles: []
      }
    }
  }

  onFilerAudios(params={}) {
    let newState = this.state;
    const paramsArr = Object.keys(params)
    if (paramsArr.includes("userId")) { newState = update(newState, {listing: {filter: {userId: {$set: params.userId}}}}) }
    if (paramsArr.includes("page")) { newState = update(newState, {listing: {filter: {page: {$set: params.page}}}}) }
    if (paramsArr.includes("artist")) { newState = update(newState, {listing: {filter: {artist: {$set: params.artist}}}}) }
    if (paramsArr.includes("title")) { newState = update(newState, {listing: {filter: {title: {$set: params.title}}}}) }
    if (paramsArr.includes("operator")) { newState = update(newState, {listing: {filter: {operator: {$set: params.operator}}}}) }

    this.setState(newState)

    actions.fetchAudios(newState.listing.filter)
  }

  // Fetch Audios

  onFetchAudios() {
    this.setState(update(this.state, {listing: {loading: {$set: true}}}))
  }

  onFetchAudiosCompleted(response) {
    const {audios, hasMore, page} = response.data
    let updatedAudios;
    if (page > 1) {
      updatedAudios = update(this.state.listing.audios, {$splice: [[this.state.listing.audios.length, 0, ...audios]]})
    }
    else {
      updatedAudios = audios;
    }
    this.setState(update(this.state, {listing: {
      audios: {$set: updatedAudios},
      hasMore: {$set: hasMore && audios.length > 0},
      page: {$set: page},
      loading: {$set: false}
    }}))
  }

  onFetchAudiosFailed(error) {
    this.setState(update(this.state, {listing: {
      loading: {$set: false},
    }}))
  }

  // Search

  onSearchAudioCompleted(response) {
    const { artists, titles } = response.data
    this.setState(update(this.state, {listing: {search: {
      artists: {$set: artists},
      titles: {$set: titles}
    }}}))
  }

  onClearSearch() {
    this.setState(update(this.state, {listing: {search: {
      artists: {$set: []},
      titles: {$set: []}
    }}}))
  }

  onClearListing(){
    this.setState(update(this.state, {listing: {$set: this.buildListing()}}))
  }

  onDeleteAudioCompleted(response) {
    //console.log("onDeleteAudioCompleted: ", response)
    if (response.data.error) {
      message.error(response.data.error, 10)
      return
    }

    const { audioId } = response.data
    const index = this.state.listing.audios.findIndex((audio) => audio.id == audioId)
    if (index < 0) return;
    this.setState(update(this.state, {listing: {
      audios: {$splice: [[index, 1]]},
    }}))
  }

  onDeleteMapCompleted(response) {
    //console.log("onDeleteMapCompleted: ", response)
    if (response.data.error) {
      message.error(response.data.error, 10)
      return
    }

    const { mapId, audioId } = response.data
    const index = this.state.listing.audios.findIndex((a) => a.id == audioId)
    if (index < 0) return;

    this.setState(update(this.state, {listing: {
      audios: {[index]: {maps: {$apply: (maps) => maps.filter((m) => m.id != mapId) }}}
    }}))

    // this.setState(update(this.state, {listing: {
    //   audios: {$apply: (audios) => {
    //     return audios.map((audio) => {
    //       update(audio, {maps: {$set: audio.maps.filter((m) => m.id != mapId)}})
    //     })
    //   }}
    // }}))
  }
}

export default Reflux.initStore(Store);
