import React from 'react';
import { Layout, Menu, Button, Row, Col, Space, Image } from "antd";
import { UserOutlined, MenuOutlined, BarsOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import logo from '../img/logo.png'
import newIcon from '../img/new-icon.svg'
import AppContext from './app_context'

const { Header, Footer, Sider, Content } = Layout;

const menuItems = [
  { label: <Link to="/?header=how-it-works">How it works</Link>, key: 1 },
  { label: <Link to="/?header=download">Download</Link>, key: 2 },
  { label: <Link to="/?header=buy-pro">Buy PRO</Link>, key: 3 },
  { label: <Link to="/?header=contact-us">Contact us</Link>, key: 4 },
  { label: <Link to="/library"><img height="32" style={{paddingRight: 4}} src={newIcon} />Maps library</Link>, key: 5 },
];

class AppLayout extends React.PureComponent {
  static contextType = AppContext

  render() {
    //console.log("AppLayout context: ", this.context)
    return (
      <Layout id="mb-app-layout">
        <Header className="mb-header">
          <Row justify="center" className="mb-header-row">
            <Col xs={5} sm={4}>
              <Link to="/">
                <img src={logo} width="64" height="64" className="mb-logo" />
              </Link>
            </Col>
            <Col xs={3} sm={4} md={12} xl={16} className="mb-header-menu-col">
              <Menu
                theme="dark"
                mode="horizontal"
                items={menuItems}
                selectable={false}
                overflowedIndicator={<MenuOutlined className="mb-menu-btn" />}
                triggerSubMenuAction="click"
              />
            </Col>
            <Col xs={16} sm={16} md={8} xl={4} className="mb-buttons-col">
              <Space size={10}>
                {this.renderProfileButtons()}
              </Space>
            </Col>
          </Row>
        </Header>
        <Layout>
          {this.props.children}
        </Layout>

        <Footer className="mb-footer">
          MBOY Games {(new Date()).getFullYear()} / Created by Vic
          <div>
            <Link to="/terms" className="mb-footer-link">Terms of Service</Link>
            <Link to="/privacy" className="mb-footer-link">Privacy Policy</Link>
          </div>
        </Footer>
      </Layout>
    )
  }

  renderProfileButtons() {
    if (this.context.loginCompleted) {
      return (
        <Link to="/profile" className="ant-btn ant-btn-primary ant-btn-round">
          <UserOutlined />
          <span> </span>
          My profile
        </Link>
      )
    }
    else {
      return (
        <React.Fragment>
          <Link to="/login" className="ant-btn ant-btn-primary ant-btn-round">Log in</Link>
          <Link to="/signup" className="ant-btn ant-btn-primary ant-btn-round">Register</Link>
        </React.Fragment>
      )
    }
  }
}

export default AppLayout;
