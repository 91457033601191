import React from 'react';
import AppContext from './app_context';
import RouterParams from "../helpers/router_params"

class AuthLink extends React.PureComponent {
  static contextType = AppContext
  render() {
    if (this.props.type == "link")
      return (
        <a href={this.props.to} onClick={this.onClick}>
          {this.props.children}
        </a>
      )
    else
      return (
        <span onClick={this.onClick}>
          {this.props.children}
        </span>
      )
  }

  onClick = () => {
    if (this.context.loginCompleted) { this.props.routerParams.navigate(this.props.to) }
    else { this.props.routerParams.navigate('/login') }
  }
}

export default RouterParams(AuthLink);
