import {axios, API_URL} from '../helpers/axios'

class Api {
  uploadMap(data) {
    return axios.post(`${API_URL}/maps/upload`, data)
  }

  createAudio(data) {
    return axios.post(`${API_URL}/audios`, data)
  }

  updateAudio(audioId, data) {
    return axios.put(`${API_URL}/audios/${audioId}`, data)
  }

  uploadAudio(audioId, data) {
    return axios.post(`${API_URL}/audios/${audioId}/upload`, data)
  }

  uploadCover(data) {
    return axios.post(`${API_URL}/covers/upload`, data)
  }

  cancelMap(mapId, audioId) {
    return axios.post(`${API_URL}/maps/${mapId}/cancel?audio_id=${audioId}`)
  }

  publishMap(mapId, audioId) {
    return axios.patch(`${API_URL}/maps/${mapId}/publish?audio_id=${audioId}`)
  }

  publishAudio(audioId) {
    return axios.patch(`${API_URL}/audios/${audioId}/publish`)
  }

  cancelAudio(audioId) {
    return axios.post(`${API_URL}/audios/${audioId}/cancel`)
  }

  fetchAudios(params) {
    return axios.get(`${API_URL}/audios`, {params: params})
  }

  fetchAudio(audioId) {
    return axios.get(`${API_URL}/audios/${audioId}`)
  }
}
export default new Api();
