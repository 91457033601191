import Reflux from 'reflux'
import api from './api'
import catchError from '../helpers/catch_error'

const actions = Reflux.createActions([
  {fetchAudios: {asyncResult: true}},
  {searchAudio: {asyncResult: true}},
  {filerAudios: {}},
  {clearSearch: {}},
  {clearListing: {}},
  {deleteAudio: {asyncResult: true}},
  {deleteMap: {asyncResult: true}}
])

actions.fetchAudios.listen(function() {
  api.fetchAudios(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.searchAudio.listen(function() {
  api.searchAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.deleteAudio.listen(function() {
  api.deleteAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.deleteMap.listen(function() {
  api.deleteMap(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

export default actions
