import {axios, API_URL, toSnakeCase} from '../helpers/axios'

class Api {
  fetchAudios(params) {
    return axios.get(`${API_URL}/audios`, {params: toSnakeCase(params)})
  }
  searchAudio(params) {
    return axios.get(`${API_URL}/audios/search`, {params: params})
  }
  deleteAudio(audioId) {
    return axios.delete(`${API_URL}/audios/${audioId}`)
  }
  deleteMap(mapId) {
    return axios.delete(`${API_URL}/maps/${mapId}`)
  }
}
export default new Api();
