import 'antd/dist/antd.css'
import './app.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import Reflux from 'reflux'

import AppContext from './components/app_context'
import AppLayout from './components/app_layout'
import Home from './pages/home'
import Login from './pages/login'
import Signup from './pages/signup'
import ProfileEdit from './pages/profile_edit'
import ProfileSecure from './pages/profile_secure'
import Profile from './pages/profile'
import OrderStatus from './pages/order_status'
import Terms from './pages/terms'
import Privacy from './pages/privacy'
import Library from './library/library'
import Uploader from './uploader/uploader'

import store from './store'

class App extends Reflux.PureComponent {
  constructor(props) {
    super(props)
    this.store = store;
  }

  render() {
    return (
      <BrowserRouter>
        <AppContext.Provider value={this.state.context}>
          <AppLayout>
            <Routes>
              <Route path="/" element={<Home subscriptionPlans={this.state.subscriptionPlans} /> } />
              <Route path="/login" element={<Login errors={this.state.errors} />} />
              <Route path="/signup" element={<Signup errors={this.state.errors} />} />
              <Route path="/profile/edit" element={<ProfileEdit errors={this.state.errors} />} />
              <Route path="/profile/secure" element={<ProfileSecure errors={this.state.errors} />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/orders/status" element={<OrderStatus orderStatus={this.state.orderStatus} /> } />
              <Route path="/terms" element={<Terms /> } />
              <Route path="/privacy" element={<Privacy /> } />
              <Route path="/library/*" element={<Library /> } />
              <Route path="/upload/*" element={<Uploader /> } />
              {/*<Route path="*" element={<div>Not found</div>} />*/}
            </Routes>
          </AppLayout>
        </AppContext.Provider>
      </BrowserRouter>
    )
  }
}

export default App;
