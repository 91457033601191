import React from "react"
import { Button } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons';

class SubmitBtn extends React.PureComponent {
  render() {
    const { loading, disabled, onClick } = this.props;
    const label = this.props.label || "Next";
    const htmlType = this.props.htmlType || "button"

    return (
      <Button type="primary" htmlType={htmlType} shape="round" size="default" disabled={disabled} loading={loading} onClick={onClick}>
        { label }
        { label == "Next" && <ArrowRightOutlined />}
      </Button>
    )
  }
}

export default SubmitBtn;
