import React from "react"
import { Layout, Typography, Col, Row } from 'antd'
import ReactMarkdown from 'react-markdown'

const TERMS =
`
### Terms of Service

Viktor Pukman (a Sole Entrepreneur) is offering you a service, which is conditioned on your acceptance, without any modification whatsoever to the following terms, conditions, and notices.



By visiting and/or using the services offered by this website ([mboy-games.com](https://mboy-games.com)), the MBOY Editor desktop app (as found on [mboy-games.com/?header=download](https://mboy-games.com/?header=download)) or any other affiliated services (henceforth referenced as "the Platform"), you are acknowledging your full compliance to the terms and conditions listed here.



In addition to this policy, please also make sure to visit and understand our [Privacy Policy](/privacy).



As a condition of your use of this website, you warrant that you will not use this website, or any of the content obtained from this website, for any purpose that is unlawful or prohibited by these terms. If you violate any of these Terms of Use, your permission to use the website automatically terminates.



#### Description of Service



The Platform provides a desktop app called MBOY Editor for creating rhythm mapping files also known as beatmaps/charts/flow. The MBOY Editor is designed for the mapping creation only. Users should use third-party software (aka game engines) to visualize/create gameplay based on the created mapping files.



The MBOY Editor is a part of services provided by the Platform. All described Terms of Service and Privacy Policy on this website are acceptable for the MBOY Editor desktop app as well.



The Platform provides a website where users can create an account and buy a subscription. Users use this account to login inside of the MBOY Editor app.



#### Payment and Refund Terms



Prices for the Platform, including but not limited to monthly subscription plan fees to the MBOY Editor, are as displayed at this website or notified to you from time to time and may be subject to change at any time on 30 days’ notice. Such notice may be provided to you at any time by posting the changes to this website or the Platform itself.



All payments are processed by PAYEER website ([payeer.com](https://payeer.com/en/)).



All fees for the Platform will be billed to and payable by you in advance on a monthly basis.



The Platform is a low-cost service and provides no refunds for its services.



If you are unhappy with the Platform's Payment and Refund Terms for any reason please contact us at [vic.rock.official@gmail.com](mailto:vic.rock.official@gmail.com) and we will review your case and use means in our power to counteract your dissatisfaction.




#### Changes To Terms of Service

These Terms of Service are effective as of 01/01/2023 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.



We reserve the right to update or change our Terms of Service at any time and you should check these Terms of Service periodically. Your continued use of the Service after we post any modifications to the Terms of Service on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms of Service.



If we make any material changes to this Terms of Service, we will notify you either through the email address you have provided us, or by placing a prominent notice on this website.



#### Modifications To Service

The Platform reserves the right to modify or discontinue the services with or without notice to the User.

The Platform shall not be liable to User or any third party should the Platform exercise its right to modify or discontinue the services.





#### Disclaimer of Warranties

USER EXPRESSLY AGREES THAT USE OF THE SERVICE IS AT USER'S SOLE RISK.

THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.



YOU UNDERSTAND AND EXPRESSLY AGREE THAT USE OF THE PLATFORM'S SERVICES ARE AT YOUR SOLE RISK, THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF PLATFORM'S SERVICES IS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA.



EXCEPT AS EXPRESSLY SET FORTH ON OUR SITES, THE PLATFORM DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR TITLE AND IT MAKES NO WARRANTY OR REPRESENTATION REGARDING THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM'S SERVICES, REGARDING THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE PLATFORM'S SERVICES, REGARDING ANY GOODS OR SERVICES PURCHASED OR OBTAINED THROUGH THE PLATFORM'S SERVICES, REGARDING ANY TRANSACTIONS ENTERED INTO THROUGH THE PLATFORM'S SERVICES OR THAT THE PLATFORM'S SERVICES WILL MEET ANY USER'S REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE.



The links to third party sites on this website are for your convenience only, and the inclusion of such links within this web site does not imply any endorsement, guarantee, warranty or representation by the Platform.



The Platform user agrees that The Platform is hereby absolved from any and all liabilities, losses, costs and claims, including attorney's fees asserted against The Platform, its agents, officers, employees, or associates, that may arise or result from any service provided by The Platform.



THE PLATFORM MAKES NO WARRANTY THAT THE SERVICE WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE;

NOR DOES THE PLATFORM MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE SERVICE OR THAT DEFECTS IN THE SOFTWARE WILL BE CORRECTED.







#### Limitation of Liability

IN NO EVENT SHALL THE PLATFORM, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEBSITE, (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD PARTY, AND/OR (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE THE PLATFORM'S WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

WHILE THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION, WHERE CONDITIONS, WARRANTIES OR OTHER RIGHTS ARE IMPLIED IN THIS AGREEMENT OR OTHERWISE CONFERRED BY LAW, AND IT IS NOT LAWFUL OR POSSIBLE TO EXCLUDE THEM, THEN THOSE CONDITIONS, WARRANTIES OR OTHER RIGHTS WILL (BUT ONLY TO THE EXTENT REQUIRED BY LAW) APPLY TO THIS AGREEMENT.



AT THE OPTION OF THE PLATFORM, OUR LIABILITY FOR BREACH OF IMPLIED CONDITIONS OR WARRANTIES IS LIMITED, TO THE EXTENT PERMITTED BY LAW TO, IF THE BREACH RELATES TO GOODS, (I) THE REPLACEMENT OF THE GOODS OR THE SUPPLY OF EQUIVALENT GOODS, (II) THE REPAIR OF THE GOODS, (III) THE PAYMENT OF THE COST OF REPLACING THE GOODS OR OF ACQUIRING EQUIVALENT GOODS, OR (IV) THE PAYMENT OF HAVING THE GOODS REPAIRED AND, IF THE BREACH RELATES TO SERVICES, (I) THE SUPPLY OF THE SERVICES AGAIN; OR (II) THE PAYMENT OF THE COST OF HAVING THE SERVICES SUPPLIED AGAIN.



YOU SPECIFICALLY ACKNOWLEDGE THAT THE PLATFORM SHALL NOT BE LIABLE FOR USER SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.



The Platform makes no representations that The Platform's website is appropriate or available for use in your location.

Those who access or use the The Platform's website from their jurisdictions do so at their own volition and are responsible for compliance with local law.



#### Indemnity

You agree to defend, indemnify and hold harmless The Platform, its parent corporation, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to The Platform's website; (ii) your violation of any term of these Terms of Service; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that one of your User Submissions caused damage to a third party.

This defense and indemnification obligation will survive these Terms of Service and your use of The Platform's website.



#### Links

The Platform may provide, or Users may include in email, links to other websites or resources.

User acknowledges and agrees that the Platform is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such sites or resources.

Users agree that the Platform shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on such external sites or resources.
`

class Terms extends React.PureComponent {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
       <Layout.Content id="mb-terms" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Typography>
                <ReactMarkdown>
                  {TERMS}
                </ReactMarkdown>
              </Typography>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Terms;
