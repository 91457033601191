import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Layout, Typography, Button, Checkbox, Form, Input, Col, Row, Alert } from 'antd'
import { Navigate, Link } from "react-router-dom";
const { Title } = Typography;

import actions from '../actions'
import AppContext from '../components/app_context'

class Login extends React.PureComponent {
  static contextType = AppContext
  static propTypes = {
    errors: PropTypes.shape({
      loginError: PropTypes.string
    })
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.errors.loginError) actions.clearError('loginError');
  }

  render() {
    if (this.context.loginCompleted) { return <Navigate to="/profile" replace={true} /> }
    const { errors: {loginError} } = this.props;

    return (
      <Layout.Content id="mb-login" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Title level={4} className="mb-left">Log in</Title>

              {loginError && <Alert message={loginError} type="error" />}

              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{span: 16 }}
                initialValues={{remember: true}}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="remember_me"
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    >
                    Log in
                  </Button>
                  <span className="mb-form-link-pre"> or </span>
                  <Link to="/signup" className="ant-btn ant-btn-link mb-link mb-form-link">Register</Link>
                </Form.Item>
              </Form>

            </div>
          </Col>
        </Row>
      </Layout.Content>
    )
  }

  onFinish(values) {
    //console.log('Success:', values);
    actions.login(values)
  }

  onFinishFailed(errorInfo) {
    //console.log('Failed:', errorInfo);
  }
}

export default Login;
