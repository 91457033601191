import Reflux from 'reflux'
import actions from './actions'
import update from 'immutability-helper';
import { clearData } from './helpers/local_storage'
import LazyLoad from 'vanilla-lazyload'
import toCamelCase from "camelcase-object-deep"

const SUPPORT_EMAIL = ['vic.rock.official', 'gmail'].join("@")

class Store extends Reflux.Store {
  constructor(){
    super()
    this.initLazyLoad()
    this.listenToMany(actions)

    let user = gon.current_user
    if (user) { user = toCamelCase(user) }

    this.state = {
      context: {
        loginCompleted: !!user,
        user: user,
        settings: toCamelCase(gon.settings)
      },
      subscriptionPlans: null,
      orderStatus: {order: null},
      errors: {
        loginError: null,
        profileError: null
      }
    }
  }

  //TODO: move to home page only???
  initLazyLoad() {
    if (!document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        callback_enter: (el) => console.log("LAZY LOAD: ", el.getAttribute("data-src"))
      })
    }
  }

  onClearError(key){
    const newState = this.updateError(key, null)
    this.setState(newState)
  }

  updateError(key, value) {
    return update(this.state, {errors: {[key]: {$set: value}}})
  }

  onLoginCompleted(response) {
    this.setLoginCompleted(response)
  }

  onLoginFailed(response) {
    if (response.data.error) {
      const newState = this.updateError('loginError', response.data.error)
      this.setState(newState)
    }
  }

  onSignupCompleted(response) {
    this.setLoginCompleted(response)
  }

  setLoginCompleted(response) {
    // login & signup returns user data in the response body
    const user = response.data;
    let newState = this.updateError('loginError', null)
    this.setState(newState)

    window.document.location.href = '/profile' // force to reload to fix session issue
  }

  onSignupFailed(response) {
    if (response.data.errors) {
      const errorKey = Object.keys(response.data.errors)[0]
      const newState = this.updateError('loginError', `${errorKey} ${response.data.errors[errorKey]}`)
      this.setState(newState)
    }
  }

  onLogoutCompleted(){
    clearData()
    window.document.location.href = '/login'
  }

  onCreateOrderCompleted(response) {
    if (response.data.url) {
      window.location.href = response.data.url;
    }
  }
  onFetchSubscriptionPlansCompleted(response) {
    this.setState(update(this.state, {subscriptionPlans: {$set: response.data.subscriptions}}))
  }
  onFetchOrderStatusCompleted(response) {
    this.setState(update(this.state, {orderStatus: {order: {$set: response.data.order}}}))
  }
  onSendEmail(){
    window.location.href = `mailto:${SUPPORT_EMAIL}?subject=MBOY Editor&body=Hey Vic!\n`
  }

  onUpdateProfileCompleted(response) {
    if (response.data.error) {
      const newState = this.updateError('profileError', response.data.error)
      this.setState(newState)
      return
    }

    const newState = this.updateError('profileError', null)
    this.setState(newState)

    window.document.location.href = '/profile'
  }

  onSecureProfileCompleted(response) {
    if (response.data.error) {
      const newState = this.updateError('profileError', response.data.error)
      this.setState(newState)
      return
    }

    const newState = this.updateError('profileError', null)
    this.setState(newState)

    window.document.location.href = '/profile'
  }
}
export default Reflux.initStore(Store);
