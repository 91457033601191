import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Col, Row, Card, Button } from 'antd'
import { ShoppingCartOutlined } from '@ant-design/icons';
import actions from "../actions"
import RouterParams from "../helpers/router_params"
import AppContext from './app_context'

class Subscription extends React.PureComponent {
  static contextType = AppContext;

  static propTypes = {
    subscriptionPlans: PropTypes.array
  }

  componentDidMount() {
    //console.log("subscription componentDidMount:", this.props)
    if (!this.props.subscriptionPlans) actions.fetchSubscriptionPlans();
  }
  render() {
    if (!this.props.subscriptionPlans) return;
    //return this.renderPro()

    return (
      <Row justify="center" className="mb-center mb-subscriptions-cont">
        {this.props.subscriptionPlans.map((product) => <Col key={product.id} flex="none" xs={24} lg={8}>{this.renderPlan(product)}</Col>)}
      </Row>
    )
  }

  renderPlan(product) {
    //const product = this.props.subscriptionPlans.filter((p) => p.slug == 'editor-12m')[0]
    if (!product) return;

    const price1 = product.price.split('.')[0]
    const price2 = product.price.split('.')[1]

    return (
      <Card
        key={product.id}
        title={<div>{product.name}<div>unlimited track lines</div></div>}
        className={`mb-card mb-card-${product.slug}`}
      >

        {this.renderDiscountLabel(product)}

        <div className="mb-price">
          ${price1}
          <span className="mb-price-cents">.{price2}</span>
        </div>
        <Button
          type="primary"
          shape="round"
          size="large"
          className="mb-btn-large"
          icon={<ShoppingCartOutlined />}
          onClick={() => this.onBuyClick(product)}>
          Buy
        </Button>
      </Card>
    )
  }

  renderDiscountLabel(product) {
    let label = null
    if (product.slug == 'editor-6m') {
      label = 'save 33%'
    }
    else if (product.slug == 'editor-12m') {
      label = '🔥 save 50% 🔥'
    }
    else return null;

    return (
      <div className="mb-price-discount">
        <span className="mb-label">{label}</span>
      </div>
    )
  }

  onBuyClick(product) {
    if (this.context.loginCompleted) { actions.createOrder({productId: product.id}) }
    else { this.props.routerParams.navigate('/signup') }
  }
}

export default RouterParams(Subscription);
