import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Layout, Col, Row, Alert, Button } from 'antd'
import { ShoppingCartOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import actions from "../actions"
import RouterParams from "../helpers/router_params"

class OrderStatus extends React.PureComponent {
   static propTypes = {
    routerParams: PropTypes.shape({
      params: PropTypes.object,
      searchParams: PropTypes.object
    }),
    orderStatus: PropTypes.shape({
      order: PropTypes.object
    })
  }

  getOrderId = () => {
    return this.props.routerParams.searchParams.mOrderid;
  }

  componentDidMount() {
    const {order} = this.props.orderStatus
    if (!order || order.id != this.getOrderId()) actions.fetchOrderStatus({orderId: this.getOrderId()});
  }

  render() {
    const {order} = this.props.orderStatus
    if (!order || order.id != this.getOrderId()) return;

    return (
      <Layout.Content id="mb-order-status" className="mb-layout-content">
        <Row justify="center">
          {order.status == "success" && this.renderSuccess(order)}
          {order.status == "error" && this.renderError()}
          {order.status == "blank" && this.renderBlank()}
        </Row>
      </Layout.Content>
    )
  }

  renderBlank() {
    return (
      <div>
        <Alert
            message="Not processed"
            description="The order is not processed yet. Please wait a few moments."
            type="warning"
            showIcon
          />
        <div className="mb-center mb-actions">
          <a className="mb-link" onClick={() => window.location.href = window.location.href }>Refresh</a>
        </div>
      </div>
    )
  }

  renderError(){
    return (
      <div>
        <Alert
          message="Order error!"
          description="An error happen during the purchase process"
          type="error"
          showIcon
          />
        <div className="mb-center mb-actions">
          <span>Connect </span>
          <a className="mb-link" onClick={() => actions.sendEmail()}>support</a>
        </div>
      </div>
    )
  }

  renderSuccess(order){
    return (
      <div>
        <Alert
          message="Order success!"
          description={`You've purchased "${order.product.name}" successfully.`}
          type="success"
          showIcon
          />
        <div className="mb-center mb-actions">
          <span>Go to </span>
          <a className="mb-link" href="/profile">My Profile</a>
        </div>
      </div>
    )
  }
}

export default RouterParams(OrderStatus);
