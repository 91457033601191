import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Routes, Route } from "react-router-dom"
import Reflux from 'reflux'

import store from './store'
import MapUploader from './pages/map_uploader'
import AudioUploader from './pages/audio_uploader'

class Uploader extends Reflux.PureComponent {
  constructor(props) {
    super(props)
    this.store = store;
  }

  render() {
    return (
      <Routes>
        <Route path="/map" element={<MapUploader uploader={this.state.uploader} />} />
        <Route path="/audio" element={<AudioUploader uploader={this.state.uploader} />} />
      </Routes>
    )
  }
}

export default Uploader;
