import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Layout, Typography, Button, Checkbox, Form, Input, Col, Row, Alert, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { Navigate, Link } from "react-router-dom";
const { Title } = Typography;
import actions from '../actions'
import update from 'immutability-helper';
import validatePassword from '../helpers/validate_password'
import AppContext from '../components/app_context'

class ProfileSecure extends React.PureComponent {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.errors.profileError) actions.clearError('profileError');
  }

  render() {
    if (!this.context.loginCompleted) { return <Navigate to="/login" replace={true} /> }
    const { user } = this.context;
    const { errors: {profileError} } = this.props;

    return (
      <Layout.Content id="mb-profile-edit" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Title level={4} className="mb-left">Edit password</Title>

              {profileError && <Alert message={profileError} type="error" />}

              <Form
                ref={this.formRef}
                name="password-edit"
                labelCol={{ span: 8 }}
                wrapperCol={{span: 16 }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="current"
                  label="Current Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  //hasFeedback
                >
                  <Input.Password placeholder="Confirm changes by entering your password" />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="New password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        const error = validatePassword(value)
                        if (error) { return Promise.reject(error); }
                        else { return Promise.resolve(); }
                      }
                    }
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="New Password Confirm"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The two passwords don't match!"));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>


                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    >
                    Update
                  </Button>
                  <span className="mb-form-link-pre"> or </span>
                  <Link to="/profile" className="ant-btn ant-btn-link mb-link mb-form-link">Cancel</Link>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    )
  }

  onFinish = (formParams) => {
    //console.log('Submit:', formParams);

    actions.secureProfile({
      user: {
        current_password: formParams.current,
        password: formParams.password,
        password_confirmation: formParams.confirm,
      }
    })
  }

  onFinishFailed(errorInfo) {
    //console.log('onFinishFailed:', errorInfo);
  }
}

export default ProfileSecure;
