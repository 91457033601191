import Reflux from 'reflux'
import api from './api'
import catchError from '../helpers/catch_error'

const actions = Reflux.createActions([
  {initUploader: {}},
  {selectMapFile: {}},
  {selectAudioFile: {}},
  {selectCoverFile: {}},
  {selectAudioId: {}},
  {selectAudio: {}},
  {selectAudioSkip: {}},
  {uploadMap: {asyncResult: true}},
  {createAudio: {asyncResult: true}},
  {updateAudio: {asyncResult: true}},
  {uploadAudio: {asyncResult: true}},
  {fetchAudios: {asyncResult: true}},
  {fetchAudio: {asyncResult: true}},
  {uploadAudioSkip: {}},
  {uploadCover: {asyncResult: true}},
  {uploadCoverSkip: {}},
  {publish: {}},
  {publishMap: {asyncResult: true}},
  {publishAudio: {asyncResult: true}},
  {cancel: {}},
  {cancelMap: {asyncResult: true}},
  {cancelAudio: {asyncResult: true}},
])

actions.uploadMap.listen(function() {
  api.uploadMap(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.createAudio.listen(function() {
  api.createAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.updateAudio.listen(function() {
  api.updateAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.uploadAudio.listen(function() {
  api.uploadAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.uploadCover.listen(function() {
  api.uploadCover(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.publishMap.listen(function() {
  api.publishMap(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.publishAudio.listen(function() {
  api.publishAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.cancelMap.listen(function() {
  api.cancelMap(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.cancelAudio.listen(function() {
  api.cancelAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.fetchAudios.listen(function() {
  api.fetchAudios(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.fetchAudio.listen(function() {
  api.fetchAudio(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

export default actions
