import React from "react"
import { Layout, Row, Col, Typography, Steps } from 'antd'
import UploadMapStep from '../components/upload_map_step'
import AudioInfoStep from '../components/audio_info_step'
import UploadAudioStep from '../components/upload_audio_step'
import UploadCoverStep from '../components/upload_cover_step'
import AudioSelectStep from '../components/audio_select_step'
import PublishStep from '../components/publish_step'
import actions from '../actions'
import RouterParams from '../../helpers/router_params'
import AppContext from '../../components/app_context';
import { Navigate } from "react-router-dom";

const STEPS_ITEMS = [
  {
    key: "mapFile",
    title: 'Map File'
  },
  // hide audioSelect step in the header
  // {
  //   key: "audioSelect",
  //   title: 'Audio Select'
  // },
  {
    key: "audioInfo",
    title: 'Audio Info'
  },
  {
    key: "audioFile",
    title: 'Audio File'
  },
  {
    key: "coverFile",
    title: 'Cover File'
  },
  {
    key: "publish",
    title: 'Publish'
  },
];

class MapUploader extends React.PureComponent {
  static contextType = AppContext

  componentDidMount(){
    const audioId = this.props.routerParams.searchParams.audioId
    actions.initUploader({resource: "map", attachedAudioId: audioId})
  }

  componentWillUnmount(){
    actions.initUploader() // just to clear it
  }

  render() {
    if (!this.context.loginCompleted) { return <Navigate to="/login" replace={true} /> }

    const {currentStep, redirectToLibrary} = this.props.uploader;
    if (redirectToLibrary) { return <Navigate to="/library" replace={true} /> }
    return (
      <Layout.Content id="mb-map-uploader" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Typography.Title level={4} className="mb-left">Upload map</Typography.Title>

              <Steps
                current={this.getCurrenStepIndex(currentStep)}
                items={STEPS_ITEMS}
              />

              {currentStep == "mapFile" && <UploadMapStep uploader={this.props.uploader} />}
              {currentStep == "audioSelect" && <AudioSelectStep uploader={this.props.uploader} />}
              {currentStep == "audioInfo" && <AudioInfoStep uploader={this.props.uploader} />}
              {currentStep == "audioFile" && <UploadAudioStep uploader={this.props.uploader} />}
              {currentStep == "coverFile" && <UploadCoverStep uploader={this.props.uploader} />}
              {currentStep == "publish" && <PublishStep uploader={this.props.uploader} />}

            </div>
          </Col>
        </Row>
      </Layout.Content>
    )
  }

  getCurrenStepIndex(currentStep){
    let stepKey = currentStep;
    if (stepKey == "audioSelect") { stepKey = "audioInfo" } // hide audioSelect step in the header
    return STEPS_ITEMS.map((i) => i.key).indexOf(stepKey)
  }

}

export default RouterParams(MapUploader);
