import React from "react"
import { Layout, Typography, Col, Row } from 'antd'
import ReactMarkdown from 'react-markdown'

const PRIVACY =
`
### Privacy Policy

This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the [mboy-games.com](https://mboy-games.com) website, MBOY Editor desktop app and any other affiliated services (the Platform).



We use your Personal Information only for providing and improving the Platform. By using the Platform, you agree to the collection and use of information in accordance with this policy.



#### Information Collection And Use

While using the Platform, we may ask you to provide us with a valid email address that can be used to contact or identify you.



#### Cookies and Log Data

Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive.



Like many sites, the Platform’s website uses "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the website.



We may also use third party services such as Google Analytics that collect, monitor and analyze traffic on the Platform’s website.



#### Security

The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.



#### Changes To This Privacy Policy

This Privacy Policy is effective as of 01/01/2023 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.



We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Platform after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified Privacy Policy.



If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on this website.

#### Data Controller

Hi, I am Viktor Pukman (commonly known as Vic or vfpe) and I am your data controller. If you have any privacy concerns or requests to exercise your legal rights, don't hesitate to contact me directly at the address listed below.



Email: [vic.rock.official@gmail.com](mailto:vic.rock.official@gmail.com)
`
class Privacy extends React.PureComponent {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
       <Layout.Content id="mb-privacy" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Typography>
                <ReactMarkdown>
                  {PRIVACY}
                </ReactMarkdown>
              </Typography>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Privacy;
