import React from "react";
import ReactDOM from 'react-dom/client';
import App from "../app";

document.addEventListener("DOMContentLoaded", () => {
  const container = document.createElement("div")
  document.body.appendChild(container)

  ReactDOM.createRoot(container).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
})
