import React from "react"
import { List, Avatar, Button, Typography, Badge, Space, Dropdown, Popconfirm, Tooltip } from 'antd'
import { DownloadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";

import AuthLink from '../../components/auth_link'
import { downloadAudioZip } from "../../helpers/download_helper"
import defaultCoverImg from '../../img/maps/cover.png'
import { FaRegMap } from "react-icons/fa6";
import AppContext from '../../components/app_context'
import actions from '../actions'
import { InfoCircleOutlined } from '@ant-design/icons';

class AudioItem extends React.PureComponent {
  static contextType = AppContext;
  static defaultProps = {
    previewMode: false,
    checkbox: null
  }

  hasOnlyAudio() {
    return this.props.audio.maps.length == 0
  }

  hasOnlyMaps(){
    return !this.props.audio.url
  }

  render() {
    const { audio } = this.props
    let maps = audio.maps;
    const showCheckbox = this.props.checkbox != null
    return (
      <List.Item className={`mb-audio-item ${this.props.className ? this.props.className : ""}`}
        actions={[]}
        extra={
          <Space direction="vertical">
            <Button
              shape="round"
              icon={<DownloadOutlined />}
              size="medium"
              onClick={() => this.onDowloadPress(audio) }
            />
          </Space>
        }
      >
        {showCheckbox && this.props.checkbox}
        <List.Item.Meta
          avatar={<Avatar size={128} shape="square" src={this.coverUrl(audio)} />}
          title={this.renderTitle(audio)}
          description={
            <>
              <Space direction="vertical">
                {this.hasOnlyMaps() && <Badge count="Only map" color="blue" />}
                {this.hasOnlyAudio() && <Badge count="Only audio" color="grey" />}

                {audio.url && <audio className="mb-audio-player" controls preload="none" src={audio.url}>
                  <a href={audio.url}> Download audio </a>
                </audio>}

                {maps.map((m, index) => this.renderMap(m, index)) }
              </Space>
            </>
          }
        />
      </List.Item>
    );
  }

  renderTitle(audio) {
    const menuItems = this.buildMenuItems()
    return (
      <span>
        {audio.fullTitle}
        {!this.hasOnlyMaps() && <Tooltip title={this.licenseLabel(audio)}>
          <InfoCircleOutlined className="mb-cc-icon" />
        </Tooltip>}
        {
          !this.props.previewMode && <Dropdown menu={{items: menuItems}} placement="bottom" arrow={false} trigger="click">
            <Button className="mb-kebab-btn" shape="circle" icon={<HiDotsVertical style={{fontSize: 16}} />} />
          </Dropdown>
        }
      </span>
    )
  }

  licenseLabel(audio) {
    return (
      <span>Audio License: <a target="_blank" href="https://creativecommons.org/share-your-work/cclicenses/">{audio.license}</a></span>
    )
  }

  renderMap(m, index) {
    let number;
    if (index > 0) {
      number = `(${index+1})`
    }
    else {
      number = ""
    }

    let userNickname = `user#${m.userId}`
    if (m.userNickname != null) { userNickname = m.userNickname }
    // use previewMode to prevent user leave the uploader page without save
    return (
      <span key={index}>
        <span className="mb-map-line-details">
          <a href={m.url} target="_blank">Map {number}</a>
          {` by `}
          <Link to={`/library?user_id=${m.userId}`} target={this.props.previewMode ? '_blank' : null}>{userNickname}</Link>
        </span>
        <span className="mb-map-line-actions">
          {this.renderMapDeleteBtn(m)}
        </span>
      </span>
    )
  }

  coverUrl(audio) {
    const url = audio?.cover?.url
    if (url) {
      return url;
    }
    else {
      return defaultCoverImg;
    }
  }

  onDowloadPress = (audio) => {
    downloadAudioZip(audio)
  }

  attachMapUrl(audio) {
    return `/upload/map?audioId=${audio.id}`
  }

  buildMenuItems() {
    let items = []
    const { audio } = this.props
    const canDelete = this.context.loginCompleted && audio.userId == this.context.user.id
    if (canDelete) {
      items.push({icon: <DeleteOutlined style={{fontSize: 14}} />, label: this.renderDeleteBtn(audio)})
    }

    items.push({icon: <FaRegMap />, label: <AuthLink to={this.attachMapUrl(audio)}>Attach map</AuthLink>  })

    return items.map((option, index) => {
      option.key = index
      return option
    })
  }

  renderDeleteBtn = (audio) => {
    return (
      <Popconfirm
        title="Are you sure to delete this audio?"
        onConfirm={() => actions.deleteAudio(audio.id) }
        okText="Yes, delete"
        cancelText="No"
      >
        <span>
          Delete audio
        </span>
      </Popconfirm>
    )
  }

  renderMapDeleteBtn = (map) => {
    if (this.props.previewMode) return;

    const canDelete = this.context.loginCompleted && map.userId == this.context.user.id
    if (!canDelete) { return; }
    return (
      <Popconfirm
        title="Are you sure to delete this map?"
        onConfirm={() => actions.deleteMap(map.id) }
        okText="Yes, delete"
        cancelText="No"
      >
        <DeleteOutlined className="mb-delete-map-btn" />
      </Popconfirm>
    )
  }

}

export default AudioItem;
