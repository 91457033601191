import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Layout, Typography, Button, Checkbox, Form, Input, Col, Row, Alert, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { Navigate, Link } from "react-router-dom";
const { Title } = Typography;
import actions from '../actions'
import update from 'immutability-helper';

import AppContext from '../components/app_context'

class ProfileEdit extends React.PureComponent {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.errors.profileError) actions.clearError('profileError');
  }

  render() {
    if (!this.context.loginCompleted) { return <Navigate to="/login" replace={true} /> }
    const { user } = this.context;
    const { errors: {profileError} } = this.props;

    return (
      <Layout.Content id="mb-profile-edit" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Title level={4} className="mb-left">Edit profile</Title>

              {profileError && <Alert message={profileError} type="error" />}

              <Form
                ref={this.formRef}
                name="profile-edit"
                labelCol={{ span: 8 }}
                wrapperCol={{span: 16 }}
                initialValues={{
                  nickname: user.nickname,
                  email: user.email
                }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                autoComplete="off"
              >

                <Form.Item
                  name="nickname"
                  label="Nickname"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Please input your nickname!',
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid email!',
                    },
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="avatarFile"
                  label="Avatar"
                  valuePropName="file"
                  getValueFromEvent={(e) => e.file}
                >
                  <Upload
                    beforeUpload={(file) => false}
                    accept=".png, .jpeg, .jpg"
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                </Form.Item>



                <Form.Item
                  name="password"
                  label="Current Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    }
                  ]}
                  //hasFeedback
                >
                  <Input.Password placeholder="Confirm changes by entering your password" />
                </Form.Item>


                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    >
                    Update
                  </Button>
                  <span className="mb-form-link-pre"> or </span>
                  <Link to="/profile" className="ant-btn ant-btn-link mb-link mb-form-link">Cancel</Link>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    )
  }

  onFinish = (formParams) => {
    //console.log('Submit:', formParams);

    const data = new FormData();
    if (formParams.avatarFile && formParams.avatarFile?.status != "removed") {
      data.append('avatar_file', formParams.avatarFile)
    }
    data.append('nickname', formParams.nickname)
    data.append('email', formParams.email)
    data.append('password', formParams.password)

    actions.updateProfile(data)
  }

  onFinishFailed(errorInfo) {
    //console.log('onFinishFailed:', errorInfo);
  }
}

export default ProfileEdit;
