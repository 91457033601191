import Reflux from 'reflux'
import actions from './actions'
import update from 'immutability-helper';
import { message } from 'antd';
import { redirect } from "react-router-dom";

class Store extends Reflux.Store {
  constructor(){
    super()
    this.listenToMany(actions)
    this.state = {
      uploader: this.buildUploader()
    }
  }

  buildUploader() {
    return {
      resource: null,
      currentStep: null,

      map: null,
      mapFile: null,

      audioInfo: {},
      audio: null,
      audioFile: null,

      cover: null,
      coverFile: null,

      published: false,
      loading: false,
      canceling: false,

      audios: [],
      selectedAudioId: null,
      attachedAudioId: null,
      hasMore: false,
      filter: {
        artist: null,
        title: null,
        operator: null,
        page: 1,
      },
      redirectToLibrary: false,
    }
  }

  onInitUploader({resource=null, attachedAudioId=null}={}) {
    let uploader = this.buildUploader()
    let currentStep = null;
    if (resource == "audio") { currentStep = "audioInfo" }
    else if (resource == "map") { currentStep = "mapFile" }
    uploader = update(uploader, {
      resource: {$set: resource},
      currentStep: {$set: currentStep},
      attachedAudioId: {$set: attachedAudioId}
    })

    this.setState(update(this.state, {
      uploader: {$set: uploader}
    }))
  }

  setUploaderLoading(value){
    this.setState(update(this.state, {uploader: {loading: {$set: value}}}))
  }

  onSelectMapFile(file) {
    this.setState(update(this.state, {uploader: {mapFile: {$set: file}}}))
  }

  // create audio

  onCreateAudio() {
    this.setUploaderLoading(true)
  }

  onCreateAudioCompleted(response){
    if (response.data.error) {
      message.error(response.data.error, 10)
      this.setUploaderLoading(false)
      return
    }

    this.setState(update(this.state, {uploader: {
      audio: {$set: response.data.audio},
      cover: {$set: response.data.audio.cover}, // set cover even it's null
      loading: {$set: false},
      currentStep: {$set: "audioFile"}
    }}))
  }

  onCreateAudioFailed(){
    //TODO: show validation errors
    this.setUploaderLoading(false)
  }

  // update audio

  onUpdateAudio() {
    this.setUploaderLoading(true)
  }

  onUpdateAudioCompleted(response) {
    if (response.data.error) {
      message.error(response.data.error, 10)
      this.setUploaderLoading(false)
      return
    }

    this.setState(update(this.state, {uploader: {
      audio: {$set: response.data.audio},
      loading: {$set: false},
      currentStep: {$set: "audioFile"}
    }}))
  }

  onUpdateAudioFailed() {
    //TODO: show validation errors
    this.setUploaderLoading(false)
  }

  ////////

  onSelectAudioFile(file) {
    this.setState(update(this.state, {uploader: {audioFile: {$set: file}}}))
  }

  onSelectCoverFile(file) {
    this.setState(update(this.state, {uploader: {coverFile: {$set: file}}}))
  }

  onUploadMap() {
    this.setUploaderLoading(true)
  }

  onUploadMapCompleted(response) {
    //console.log(response)
    if (response.data.error) {
      message.error(response.data.error, 10)
      this.setUploaderLoading(false)
      return
    }

    this.setState(update(this.state, {uploader: {
      map: {$set: response.data.map},
      audioInfo: {$set: response.data.map.audioData}
    }}))

    if (this.state.uploader.attachedAudioId != null) {
      // get audio
      actions.fetchAudio(this.state.uploader.attachedAudioId)
    }
    else {
      // search audio
      this.searchAudios()
    }
  }

  onFetchAudioCompleted(response) {
    const audio = response.data.audio

    this.setState(update(this.state, {uploader: {
      audio: {$set: audio},
      cover: {$set: audio?.cover},
      mapFile: {$set: null},
      loading: {$set: false},
      currentStep: {$set: "publish"}
    }}))
  }

  onFetchAudioFailed(response) {
    this.searchAudios()
  }

  searchAudios() {
    const { title, artist } = this.state.uploader.audioInfo;
    const filter = {title, artist, page: 1, operator: 'and'}
    this.setState(update(this.state, {uploader: {filter: {$set: filter}}}))
    actions.fetchAudios(filter)
  }

  onFetchAudiosCompleted(response) {
    const { audios, page, hasMore } = response.data

    if (page == 1 && audios.length == 0) {
      this.setState(update(this.state, {uploader: {
        mapFile: {$set: null},
        loading: {$set: false},
        currentStep: {$set: "audioInfo"}
      }}))
    }
    else {
      let updatedAudios;
      if (page > 1) {
        updatedAudios = update(this.state.uploader.audios, {$splice: [[this.state.uploader.audios.length, 0, ...audios]]})
      }
      else {
        updatedAudios = audios;
      }

      this.setState(update(this.state, {uploader: {
        mapFile: {$set: null},
        loading: {$set: false},
        audios: {$set: updatedAudios},
        filter: {page: {$set: page}},
        hasMore: {$set: hasMore},
        currentStep: {$set: "audioSelect"}
      }}))
    }
  }

  // when click on the checkbox
  onSelectAudioId(audioId) {
    if (this.state.uploader.selectedAudioId != null && audioId == this.state.uploader.selectedAudioId) { audioId = null } // allow to unset value

    if (audioId == null) {
      this.setState(update(this.state, {uploader: {
        selectedAudioId: {$set: null},
        audio: {$set: null},
        cover: {$set: null}
      }}))
    }
    else {
      // should exist in the loaded audios
      // we also validate it in onSelectAudio
      const audio = this.state.uploader.audios.filter((a) => a.id == audioId)[0]
      this.setState(update(this.state, {uploader: {
        selectedAudioId: {$set: audioId},
        audio: {$set: audio},
        cover: {$set: audio?.cover}
      }}))
    }
  }

  // when finish the step
  onSelectAudio() {
    const { selectedAudioId, audio } = this.state.uploader;
    if (audio) {
      this.setState(update(this.state, {uploader: {
        loading: {$set: false},
        currentStep: {$set: "publish"}
      }}))
    }
    else {
      this.setState(update(this.state, {uploader: {
        loading: {$set: false},
        currentStep: {$set: "audioInfo"}
      }}))
    }
  }

  onSelectAudioSkip() {
    this.setState(update(this.state, {uploader: {
      loading: {$set: false},
      currentStep: {$set: "audioInfo"}
    }}))
  }

  onUploadAudio() {
    this.setUploaderLoading(true)
  }

  onUploadAudioCompleted(response) {
    //console.log("onUploadAudioCompleted:", response)

    if (response.data.error) {
      message.error(response.data.error, 10)
      this.setUploaderLoading(false)
      return
    }

    this.setState(update(this.state, {uploader: {
      audio: {$set: response.data.audio},
      audioFile: {$set: null},
      loading: {$set: false},
      currentStep: {$set: "coverFile"}
    }}))
  }

  onUploadAudioSkip() {
    this.setState(update(this.state, {uploader: {
      audioFile: {$set: null},
      loading: {$set: false},
      currentStep: {$set: "coverFile"}
    }}))
  }

  onUploadCover() {
    this.setUploaderLoading(true)
  }

  onUploadCoverCompleted(response) {
    if (response.data.error) {
      message.error(response.data.error, 10)
      this.setUploaderLoading(false)
      return
    }

    this.setState(update(this.state, {uploader: {
      cover: {$set: response.data.cover},
      //map: {audio: {cover: {$set: response.data.cover}}},
      coverFile: {$set: null},
      loading: {$set: false},
      currentStep: {$set: "publish"}
    }}))
  }

  onUploadCoverSkip() {
    this.setState(update(this.state, {uploader: {
      coverFile: {$set: null},
      loading: {$set: false},
      currentStep: {$set: "publish"}
    }}))
  }

  // Cancel

  setUploaderCanceling(value){
    this.setState(update(this.state, {uploader: {canceling: {$set: value}}}))
  }

  onCancel() {
    const { resource, map, audio } = this.state.uploader;
    if (resource == "map") {
      actions.cancelMap(map.id, audio?.id)
    }
    else if (resource == "audio") {
      actions.cancelAudio(audio.id)
    }
  }

  onCancelCompleted(response) {
    //console.log("onCancelUpload(response)", response)
    if (response.data.error) {
      message.error(response.data.error, 10)
      this.setState(update(this.state, {uploader: {canceling: {$set: false}}}))
      return
    }

    if (this.state.uploader.attachedAudioId) {
      // redirect to library when cancel attach to audio case
      this.setState(update(this.state, {uploader: {redirectToLibrary: {$set: true}}}))
    }
    else {
      actions.initUploader({resource: this.state.uploader.resource})
    }
  }

  onCancelMap() { this.setUploaderCanceling(true) }
  onCancelMapCompleted(response) { this.onCancelCompleted(response) }
  onCancelAudio() { this.setUploaderCanceling(true) }
  onCancelAudioCompleted(response) { this.onCancelCompleted(response) }

  // Publish

  onPublish() {
    const { resource, map, audio } = this.state.uploader;
    if (resource == "map") {
      actions.publishMap(map.id, audio.id)
    }
    else if (resource == "audio") {
      actions.publishAudio(audio.id)
    }
  }

  onPublishCompleted() {
    this.setState(update(this.state, {
      uploader: {published: {$set: true}, loading: {$set: false}},
    }))
  }

  onPublishMap() { this.setUploaderLoading(true) }
  onPublishMapCompleted(response) { this.onPublishCompleted() }
  onPublishAudio() { this.setUploaderLoading(true) }
  onPublishAudioCompleted(response) { this.onPublishCompleted() }
}
export default Reflux.initStore(Store);
