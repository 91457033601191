import React from "react"
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import SubmitBtn from './submit_btn'
import CancelBtn from './cancel_btn'
import actions from '../actions'
import AudioItem from '../../library/components/audio_item'
import { Navigate } from "react-router-dom";
import AppContext from '../../components/app_context'

class PublishStep extends React.PureComponent {
  static contextType = AppContext

  render() {
    const { map, audio, cover, loading, canceling, published} = this.props.uploader;
    if (published) { return <Navigate to={`/library?userId=${this.context.user.id}`} replace={true} /> }

    return (
      <div>
        <AudioItem
          audio={{...audio, cover: cover, maps: map ? [map] : []}}
          previewMode={true}
          className="mb-audio-item-publish"
        />

        <div className="mb-steps-action">
          <SubmitBtn label="Publish" onClick={this.onPublish} loading={loading} />
          <CancelBtn loading={loading} canceling={canceling} onClick={this.onCancel} />
        </div>
      </div>
    )
  }

  onPublish = () => {
    actions.publish()
  }

  onCancel = () => {
    actions.cancel()
  }
}

export default PublishStep;
