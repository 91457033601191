import React from "react"
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import SubmitBtn from './submit_btn'
import actions from '../actions'

class UploadMapStep extends React.PureComponent {
  render() {
    const { mapFile, loading } = this.props.uploader
    const isSubmitDisabled = mapFile == null
    return (
       <div>
        <Upload.Dragger
          beforeUpload={this.onSelectMapFile}
          accept=".mboy"
          onRemove={this.onRemoveMapFile}
          fileList={mapFile ? [mapFile] : []}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Upload map file made in MBOY Editor with extension .mboy</p>
          <p className="ant-upload-hint">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>

        <div className="mb-steps-action">
          <SubmitBtn disabled={isSubmitDisabled} onClick={this.onUploadMapFile} loading={loading} />
        </div>
      </div>
    )
  }

  onSelectMapFile = (file) => {
    //console.log("onSelectMapFile: ", file)
    //TODO: ignore if extension is not .mboy
    // file.size - to validate by size
    if (!file) { return false; }
    actions.selectMapFile(file)
    return false; // important to return false
  }

  onRemoveMapFile = () => {
    //console.log("onRemoveMapFile: ")
    actions.selectMapFile(null)
  }

  // https://4x.ant.design/components/upload/
  // https://4x.ant.design/components/steps/#header
  onUploadMapFile = () => {
    //console.log("onUploadMapFile: ", this.props.uploader.mapFile)

    const data = new FormData();
    data.append('file', this.props.uploader.mapFile)
    actions.uploadMap(data)
  }
}

export default UploadMapStep;
