import React from "react"
import { Layout, Row, Col, Typography, Steps } from 'antd'
import AudioInfoStep from '../components/audio_info_step'
import UploadAudioStep from '../components/upload_audio_step'
import UploadCoverStep from '../components/upload_cover_step'
import PublishStep from '../components/publish_step'
import actions from '../actions'
import AppContext from '../../components/app_context';
import { Navigate } from "react-router-dom";

const STEPS_ITEMS = [
  {
    key: "audioInfo",
    title: 'Audio Info'
  },
  {
    key: "audioFile",
    title: 'Audio File'
  },
  {
    key: "coverFile",
    title: 'Cover File'
  },
  {
    key: "publish",
    title: 'Publish'
  },
];

class AudioUploader extends React.PureComponent {
  static contextType = AppContext

  componentDidMount(){
    actions.initUploader({resource: "audio"})
  }

  componentWillUnmount(){
    actions.initUploader() // just to clear it
  }

  render() {
    if (!this.context.loginCompleted) { return <Navigate to="/login" replace={true} /> }

    const { currentStep } = this.props.uploader;
    return (
      <Layout.Content id="mb-audio-uploader" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Typography.Title level={4} className="mb-left">Upload audio {this.context?.user?.nickname}</Typography.Title>

              <Steps
                current={STEPS_ITEMS.map((i) => i.key).indexOf(currentStep)}
                items={STEPS_ITEMS}
              />

              {currentStep == "audioInfo" && <AudioInfoStep uploader={this.props.uploader} />}
              {currentStep == "audioFile" && <UploadAudioStep uploader={this.props.uploader} />}
              {currentStep == "coverFile" && <UploadCoverStep uploader={this.props.uploader} />}
              {currentStep == "publish" && <PublishStep uploader={this.props.uploader} />}

            </div>
          </Col>
        </Row>
      </Layout.Content>
    )
  }
}

export default AudioUploader;
