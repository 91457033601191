export function getData(key) {
  const item = window.localStorage.getItem(key)
  if (item) {
    const json = JSON.parse(item)
    if (json.expiry !== null && (new Date()).getTime() > json.expiry) {
      window.localStorage.removeItem(key)
      return null;
    }
    else { return json.data; }
  }
  else { return null; }
}

export function setData(key, data, expiry=null) {
  if (data) {
    window.localStorage.setItem(key, JSON.stringify({data: data, expiry: expiry}))
  }
  else {
    window.localStorage.removeItem(key)
  }
}

export function clearData() { window.localStorage.clear() }
