import React from "react"
import { Layout, Row, Col, List, Avatar, Button, Input, Space, Divider, Skeleton, Dropdown, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import actions from '../actions'
import { DownloadOutlined, UploadOutlined, PlusOutlined, CloudUploadOutlined, SoundOutlined, FileMarkdownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { FaRegMap } from "react-icons/fa6";
import { SiAudiomack } from "react-icons/si";
import { Link } from "react-router-dom";

import RouterParams from "../../helpers/router_params"
import AppContext from '../../components/app_context'
import AudioItem from "../components/audio_item"
import SearchBox from "../components/search_box"

class Listing extends React.PureComponent {
  static contextType = AppContext;

  componentDidMount(){
    //console.log("componentDidMount: ")
    const userId = this.props.routerParams.searchParams.userId
    actions.filerAudios({userId: userId, page: 1, artist: null, title: null, operator: null});
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState){
    //console.log("componentDidUpdate: ")
    const userId = this.props.routerParams.searchParams.userId
    if (prevProps.listing.filter.userId != userId) {
      //console.log("update to ", userId)
      actions.filerAudios({userId: userId, page: 1, artist: null, title: null, operator: null});
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount(){
    actions.clearListing();
  }

  render() {
    const {audios, hasMore, search, filter, loading} = this.props.listing;
    return (
      <Layout.Content id="mb-listing" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              {this.renderHeader()}

              <InfiniteScroll
                dataLength={audios.length}
                next={this.onLoadMore}
                hasMore={hasMore}
                loader={
                  <Skeleton
                    avatar={{shape: "square", size: 128}}
                    paragraph={{
                      rows: 2,
                    }}
                    active
                  />
                }
                scrollableTarget="scrollable_container"
              >
                <List
                  //itemLayout="vertical"
                  //size="large"
                  dataSource={audios}
                  renderItem={(audio) => <AudioItem audio={audio} />}
                  loading={audios.length == 0 && loading}
                />
              </InfiniteScroll>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    );
  }

  renderHeader(){
    const {filter} = this.props.listing;
    if (filter.userId) {
      return (
        <>
          <Link to="/library"><ArrowLeftOutlined /> Show all</Link>
          <Typography.Title level={4}>Uploaded by user</Typography.Title>
        </>
      )
    }
    else {
      return (
        <>
          <Typography.Title level={4}>Maps and audios library</Typography.Title>
          {this.renderSearchBox()}
        </>
      )
    }
  }

  renderSearchBox(){
    const {search} = this.props.listing;
    return (
      <Row justify="center">
        <Col xs={24} sm={24} md={17} lg={17} xl={17} xxl={17}>
          <SearchBox search={search} />
        </Col>
        <Col xs={24} sm={24} md={1} lg={1} xl={1} xxl={1} style={{minHeight: 12}}>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Dropdown
              menu={{
                items: [
                  {key: 0, icon: <FaRegMap style={{fontSize: 18}} />, label: <a style={{fontSize: 15}} onClick={() => this.onUploadPress('/upload/map')}>Upload map</a>},
                  {key: 1, icon: <SiAudiomack style={{fontSize: 18}} />, label: <a style={{fontSize: 15}} onClick={() => this.onUploadPress('/upload/audio')}>Upload audio</a>}
                ]
              }}
              placement="bottom"
              arrow={false}
              trigger="click"
            >
            <Button className="mb-upload-btn" type="primary" shape="round" danger icon={<PlusOutlined />} size="large">
              Upload
            </Button>
          </Dropdown>
        </Col>
      </Row>
    )
  }

  onUploadPress = (url) => {
    if (this.context.loginCompleted) { this.props.routerParams.navigate(url) }
    else { this.props.routerParams.navigate('/login') }
  }

  onLoadMore = () => {
    if (this.props.listing.loading) return;
    actions.filerAudios({page: this.props.listing.filter.page + 1});
  }
}

export default RouterParams(Listing);
