import Reflux from 'reflux'
import api from './api'

function catchError(error, failedCallback) {
  if (error.response) { failedCallback(error.response) }
  else if (error.request) { console.log(error.request) }
  else { console.log('catchError:', error) }
}

const actions = Reflux.createActions([
  {login: { asyncResult: true }},
  {signup: { asyncResult: true }},
  {logout: { asyncResult: true }},
  {createOrder: { asyncResult: true }},
  {fetchSubscriptionPlans: { asyncResult: true}},
  {fetchOrderStatus: { asyncResult: true }},
  {updateProfile: { asyncResult: true }},
  {secureProfile: { asyncResult: true }},
  'sendEmail',
  'clearError',
])

actions.login.listen(function(params) {
  api.login(params).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.signup.listen(function(params) {
  api.signup(params).then(this.completed).catch((error) => catchError(error, this.failed))
})

actions.logout.listen(function(params) {
  api.logout(params).then(this.completed).catch((error) => catchError(error, this.failed))
})

actions.createOrder.listen(function(params) {
  api.createOrder(params).then(this.completed).catch((error) => catchError(error, this.failed))
})

actions.fetchSubscriptionPlans.listen(function(){ api.fetchSubscriptionPlans().then(this.completed).catch((error) => catchError(error, this.failed)) })

actions.fetchOrderStatus.listen(function(params) {
  api.fetchOrderStatus(params).then(this.completed).catch((error) => catchError(error, this.failed))
})

actions.updateProfile.listen(function() {
  api.updateProfile(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

actions.secureProfile.listen(function() {
  api.secureProfile(...arguments).then((response) => this.completed(response)).catch((error) => catchError(error, this.failed))
})

export default actions
