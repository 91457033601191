import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import Reflux from 'reflux'

import store from './store'
import Listing from './pages/listing'

class Library extends Reflux.PureComponent {
  constructor(props) {
    super(props)
    this.store = store;
  }

  render() {
    return (
      <Routes>
        <Route path="/" element={<Listing listing={this.state.listing} />} />
      </Routes>
    )
  }
}

export default Library;
