import JSZip from "jszip";
import axios from "axios";
import fileExt from "file-extension";
import { saveAs } from "file-saver";

const download = (zip, item) => {
  return axios.get(item.url, { responseType: "blob" }).then((response) => {
    zip.file(item.name, response.data, {binary: true});
  })
}

const downloadAudioZip = (audio) => {
  const zip = new JSZip();
  const files = []
  const title = audio.fullTitle.trim()
  if (audio.url) {
    files.push({name: `audio.${fileExt(audio.url)}`, url: audio.url})
  }
  audio.maps.forEach((map) => {
    if (map.url) {
      files.push({name: `map#${map.id}.${fileExt(map.url)}`, url: map.url})
    }
  })
  if (audio.cover?.url) {
    files.push({name: `cover.${fileExt(audio.cover.url)}`, url: audio.cover.url})
  }

  // prepend title to file if file is only one
  if (files.length == 1) {
    files[0]['name'] = `${title} - ${files[0]['name']}`
  }

  const downloads = files.map((item) => download(zip, item));
  Promise.all(downloads)
    .then(() => {
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, `${title}.zip`);
      })
    })
    .catch((error) => {
      console.log(error)
    })
}

export {downloadAudioZip}
