import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import promoImg from '../img/promo8.png'
import highPImg from '../img/audio-editing_2.png'
import readyToUseImg from '../img/unboxing.png' // '../img/clock_2.png'
import gameAgnosticImg from '../img/color-pencils.png'
import arrowImg from '../img/forward.png'
import windowsImg from '../img/windows-logo.png'
import macImg from '../img/mac-os-logo.png'
import ubuntuImg from '../img/ubuntu-logo.png'

import unityImg from '../img/unity-logo128.png'
import unrealImg from '../img/unreal-logo128.png'
import godotImg from '../img/godot-logo128.png'
import cocosImg from '../img/cocos-logo128.png'
import gmakerImg from '../img/gmaker-logo128.png'

import redditImg from '../img/reddit_3.png'
import ytImg from '../img/youtube_3.png'
import githubImg from '../img/github-sign.png'
import steamImg from '../img/steam_w_64.png'

import { Layout, Col, Row, Button, Steps, Badge, Divider, Typography } from 'antd'
const { Title, Text, Link } = Typography;

import { DownloadOutlined, ShoppingCartOutlined, ArrowRightOutlined, SendOutlined, FileZipOutlined, GithubOutlined, MailOutlined } from '@ant-design/icons';
import Subscription from '../components/subscription'
import Video from '../components/video'

import RouterParams from "../helpers/router_params"
import actions from '../actions'

const stepsItems = [
  { key: 1, icon: <div className="mb-step-header mb-step-1">1</div>, status: "finish", title: "Create mapping", description: "Load audio and create rhythm mapping aka flow/chart in the editor" },
  { key: 2, icon: <div className="mb-step-header mb-step-2">2</div>, status: "finish", title: "Add game logic", description: "Load mapping data and program game elements in the game engine" },
  { key: 3, icon: <div className="mb-step-header mb-step-3">3</div>, status: "finish", title: "Make it fun", description: "Adjust mapping and game logic until you get an interesting gameplay" }
]

class Home extends React.PureComponent {
  static propTypes = {
    subscriptionPlans: PropTypes.array
  }

  componentDidMount() {
    //console.log("home componentDidMount: ", this.props)
    this.scrollToHeader()
  }

  componentDidUpdate() {
    //console.log("home componentDidUpdate: ", this.props)
    this.scrollToHeader()
  }

  scrollToHeader() {
    const header = this.props.routerParams.searchParams.header
    //console.log("header: ", header)
    if (header) {
      document.getElementById(header)?.scrollIntoView();
    }
  }

  render() {
    return (
      <Layout.Content id="mb-home">

        <Row justify="center" className="mb-app">
          <Col className="mb-app-img-cont" xs={0} sm={0} md={0} lg={0} xl={12} xxl={12}>
            <img src={promoImg} className="mb-app-img" />
          </Col>
          <Col className="mb-download-cont" xs={24} sm={24} md={20} lg={20} xl={12} xxl={10}>
              <h1 key="h1" id="download" className="mb-mboy-header">MBOY Editor</h1>
              <p key="content" className="mb-mboy-moto">
                <b>ONE</b> editor for <b>ALL</b> rhythm-based games
              </p>
              <div key="button" className="mb-buttons-cont">
                <a href="https://vfpe.itch.io/mboy-editor#download" target="_blank" rel="noopener noreferrer">
                  <Button className="mb-btn-large mb-btn-download" type="primary" shape="round" icon={<DownloadOutlined />} size="large">
                    Download from Itch
                  </Button>
                </a>
                <Button
                  className="mb-btn-large mb-btn-pro"
                  type="primary"
                  shape="round"
                  icon={<ShoppingCartOutlined />}
                  size="large"
                  ghost
                  onClick={() => this.props.routerParams.navigate('/?header=buy-pro')}
                  >
                  Buy PRO
                </Button>
              </div>
              <div className="mb-platforms-cont">
                <Title level={4} className="mb-platforms-header">Cross-platform</Title>
                <span className="mb-platforms">
                  <img src={windowsImg} height="40" />
                  <img src={macImg} height="40" />
                  <img src={ubuntuImg} height="40" />
                </span>
              </div>
              <div className="mb-engines-cont">
                <Title level={4} className="mb-platforms-header">Compatible with any <span style={{whiteSpace: 'nowrap'}}>game engine</span></Title>
                <span className="mb-platforms">
                  <img src={unityImg} height="64" />
                  <img src={unrealImg} height="64" />
                  <img src={godotImg} height="64" />
                  <img src={cocosImg} height="64" style={{marginLeft: 5}} />
                  <img src={gmakerImg} height="64" style={{marginLeft: 2}} />

                </span>
              </div>
          </Col>
        </Row>

        <Layout.Content className="mb-home-content">
          <Row>
            <Col className="mb-center" xs={24}>
              <h1 className="mb-home-header" id="how-it-works">how it works</h1>
            </Col>
          </Row>
          <Row justify="center" className="mb-steps-row">
            <Col xs={0} sm={0} md={0} lg={0} xl={24} xxl={18}>
              <Steps current={0} labelPlacement="horizontal" items={stepsItems} direction="horizontal"/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={0} xxl={0} flex="none">
              <Steps current={0} labelPlacement="horizontal" items={stepsItems} direction="vertical"/>
            </Col>
          </Row>

          <Row className="mb-offset" />

          <Row>
            <Col className="mb-center" xs={24}>
              <h1 className="mb-home-header" id="examples">examples</h1>
            </Col>
          </Row>

          {/* game 1 */}
          <Row>
            <Col className="mb-center" xs={24}>
              <span className="mb-title mb-game-title">Music Tiles game</span>
            </Col>
          </Row>
          <Row justify="center" className="mb-video-header-row">
            <Col xs={0} lg={11} className="mb-center">
              <Text className="mb-feature-desc mb-video-desc">Mapping aka flow/chart in the editor</Text>
            </Col>
            <Col xs={0} md={0} lg={2} className="mb-center mb-arrow-col">
            </Col>
            <Col xs={0} lg={11} className="mb-center">
              <Text className="mb-feature-desc mb-video-desc">Gameplay based on this mapping</Text>
            </Col>
          </Row>
          <Row justify="center" className="mb-video-row">
            <Col className="mb-center" xs={24} lg={11}>
              <Row>
                <Col xs={24} lg={0}><Text className="mb-feature-desc mb-video-desc">Mapping aka flow/chart in the editor</Text></Col>
              </Row>
              <Video videoId="nOGk-8BuTnU" />
            </Col>
            <Col xs={0} lg={2} className="mb-center mb-arrow-col">
              {/*<Row>
                <Col xs={0} md={24} lg={0}><Text className="mb-feature-desc mb-video-desc">&nbsp;</Text></Col>
              </Row>*/}
              <img src={arrowImg} width="50" className="mb-arrow" />
            </Col>
            <Col className="mb-center" xs={24} lg={11}>
              <Row>
                <Col xs={24} lg={0}><Text className="mb-feature-desc mb-video-desc">Gameplay based on this mapping</Text></Col>
              </Row>
              <Video videoId="xV93EQ6t2IY" />
            </Col>
          </Row>
          <Row justify="center" className="mb-download-files-row">
            <Col lg={11} className="mb-center">
              <Button
                className="mb-btn-files"
                type="primary"
                shape="round"
                icon={<FileZipOutlined />}
                size="large"
                href="/static/Tchaikovsky-Dance_of_the_Sugar_Plum_Fairy.zip"
                >
                Download project
              </Button>
            </Col>
            <Col lg={2} className="mb-center mb-arrow-col">
            </Col>
            <Col lg={11} className="mb-center">
              <Button
                className="mb-btn-files"
                type="primary"
                shape="round"
                icon={<GithubOutlined />}
                size="large"
                href="https://github.com/v-pukman-gd/music-tiles"
                target="_blank"
                >
                Get source code
              </Button>
            </Col>
          </Row>
          <Divider />

          {/* game 2 */}
          <Row>
            <Col className="mb-center" xs={24}>
              <span className="mb-title mb-game-title">Guitar Prodigy game</span>
            </Col>
          </Row>
          <Row justify="center" className="mb-video-header-row">
            <Col xs={0} lg={11} className="mb-center">
              <Text className="mb-feature-desc mb-video-desc">Mapping aka flow/chart in the editor</Text>
            </Col>
            <Col xs={0} md={0} lg={2} className="mb-center mb-arrow-col">
            </Col>
            <Col xs={0} lg={11} className="mb-center">
              <Text className="mb-feature-desc mb-video-desc">Gameplay based on this mapping</Text>
            </Col>
          </Row>
          <Row justify="center" className="mb-video-row">
            <Col className="mb-center" xs={24} lg={11}>
              <Row>
                <Col xs={24} lg={0}><Text className="mb-feature-desc mb-video-desc">Mapping aka flow/chart in the editor</Text></Col>
              </Row>
              <Video videoId="Eweh_uEH4tI" />
            </Col>
            <Col xs={0} lg={2} className="mb-center mb-arrow-col">
              {/*<Row>
                <Col xs={0} md={24} lg={0}><Text className="mb-feature-desc mb-video-desc">&nbsp;</Text></Col>
              </Row>*/}
              <img src={arrowImg} width="50" className="mb-arrow" />
            </Col>
            <Col className="mb-center" xs={24} lg={11}>
              <Row>
                <Col xs={24} lg={0}><Text className="mb-feature-desc mb-video-desc">Gameplay based on this mapping</Text></Col>
              </Row>
              <Video videoId="KivZ1_2Z5V4" />
            </Col>
          </Row>

          <Row justify="center" className="mb-download-files-row">
            <Col lg={11} className="mb-center">
              <Button
                className="mb-btn-files"
                type="primary"
                shape="round"
                icon={<FileZipOutlined />}
                size="large"
                href="/static/TeknoAXE-Hyperbole.zip"
              >
                Download project
              </Button>
            </Col>
            <Col lg={2} className="mb-center mb-arrow-col">
            </Col>
            <Col lg={11} className="mb-center">
              <Button
                className="mb-btn-files"
                type="primary"
                shape="round"
                icon={<GithubOutlined />}
                size="large"
                href="https://github.com/v-pukman-gd/guitar-hero-clone"
                target="_blank"
                >
                Get source code
              </Button>
            </Col>
          </Row>
          <Divider />

          {/* game 3 */}
          <Row>
            <Col className="mb-center" xs={24}>
              <span className="mb-title mb-game-title">MusicBoy 3D game</span>
            </Col>
          </Row>
          <Row justify="center" className="mb-video-header-row">
            <Col xs={0} lg={11} className="mb-center">
              <Text className="mb-feature-desc mb-video-desc">Mapping aka flow/chart in the editor</Text>
            </Col>
            <Col xs={0} md={0} lg={2} className="mb-center mb-arrow-col">
            </Col>
            <Col xs={0} lg={11} className="mb-center">
              <Text className="mb-feature-desc mb-video-desc">Gameplay based on this mapping</Text>
            </Col>
          </Row>
          <Row justify="center" className="mb-video-row">
            <Col className="mb-center" xs={24} lg={11}>
              <Row>
                <Col xs={24} lg={0}><Text className="mb-feature-desc mb-video-desc">Mapping aka flow/chart in the editor</Text></Col>
              </Row>
              <Video videoId="NIX6PLt8MF0" />
            </Col>
            <Col xs={0} lg={2} className="mb-center mb-arrow-col" >
              {/*<Row>
                <Col xs={0} md={24} lg={0}><Text className="mb-feature-desc mb-video-desc">&nbsp;</Text></Col>
              </Row>*/}
              <img src={arrowImg} width="50" className="mb-arrow" />
            </Col>
            <Col className="mb-center" xs={24} lg={11}>
              <Row>
                <Col xs={24} lg={0}><Text className="mb-feature-desc mb-video-desc">Gameplay based on this mapping</Text></Col>
              </Row>
              <Video videoId="w14t8126LYw" />
            </Col>
          </Row>

          <Row justify="center" className="mb-download-files-row">
            <Col lg={11} className="mb-center">
              <Button
                className="mb-btn-files"
                type="primary"
                shape="round"
                icon={<FileZipOutlined />}
                size="large"
                href="/static/Zep_Hurme-City_life.zip"
                >
                Download project
              </Button>
            </Col>
            <Col lg={2} className="mb-center mb-arrow-col">
            </Col>
            <Col lg={11} className="mb-center">
              <Button
                className="mb-btn-files"
                type="primary"
                shape="round"
                icon={<img src={steamImg} width="20" style={{marginRight: 10}} />}
                size="large"
                href="https://store.steampowered.com/app/818620/Music_Boy_3D/"
                target="_blank"
                >
                Get on Steam
              </Button>
            </Col>
          </Row>
          <Divider />

          {/* features begin */}
          <Row justify="center">
            <Col className="mb-center" xs={24}>
              <h1 className="mb-home-header" id="features">Features</h1>
            </Col>
          </Row>
          <Row justify="center" className="mb-features-row">
            <Col lg={8} className="mb-center">
              <div className="mb-center mb-feature">
                <img src={highPImg} />
                <div className="mb-feature-desc-cont">
                  <span className="mb-title mb-feature-title">High precision</span>
                  <div className="mb-feature-desc">Create mapping that follows the rhythm precisely no matter what kind of music it is: pop, rock or classical</div>
                </div>
              </div>
            </Col>
            <Col lg={8} className="mb-center">
              <div className="mb-center mb-feature">
                <img src={readyToUseImg} className="mb-ready-img"/>
                <div className="mb-feature-desc-cont">
                  <span className="mb-title mb-feature-title">Ready to use</span>
                  <div className="mb-feature-desc">Don't spend time on custom editor development, dedicate yourself to the gameplay entirely</div>
                </div>
              </div>
            </Col>
            <Col lg={8} className="mb-center">
              <div className="mb-center mb-feature">
                <img src={gameAgnosticImg} />
                <div className="mb-feature-desc-cont">
                  <span className="mb-title mb-feature-title">Game agnostic</span>
                  <div className="mb-feature-desc">Beside of general mapping, use editor markers to attach custom behavior to specific notes</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-offset" />
          {/* features end */}

          {/* plans */}
          <Row justify="center">
            <Col className="mb-center" xs={24}>
              <h1 className="mb-home-header" id="buy-pro">Buy <span className="mb-pro-label">PRO</span> version</h1>
            </Col>
            <Subscription subscriptionPlans={this.props.subscriptionPlans} />
          </Row>
          <Row className="mb-offset" />

          {/* help */}
          <Row justify="center">
            <Col className="mb-center" xs={24}>
              <h1 className="mb-home-header" id="contact-us">Social networks</h1>
            </Col>
          </Row>
          <Row justify="center" className="mb-networks-row">
            <Col lg={6} className="mb-center">
              <div className="mb-center mb-network">
                <a href="https://www.youtube.com/watch?v=-45osdbdNgY&list=PLFfOQnFRDk-wl6DtdWCA-cTQlK7SM_sqZ">
                  <img src={ytImg} />
                </a>
                <div className="mb-feature-desc-cont">
                  <span className="mb-title mb-social-title">YouTube Channel</span>
                  <div className="mb-feature-desc">Create GuitarHero-like game tutorial series</div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-center">
              <div className="mb-center mb-network">
                <a href="https://www.reddit.com/user/victor-planet-earth">
                  <img src={redditImg} />
                </a>
                <div className="mb-feature-desc-cont">
                  <span className="mb-title mb-social-title">Reddit Thread</span>
                  <div className="mb-feature-desc">Last updates and news about the editor</div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-center">
              <div className="mb-center mb-network">
                <a href="https://www.youtube.com/channel/UCEqk33MBlI-DP93t6O2GccA/videos">
                  <img src={ytImg} />
                </a>
                <div className="mb-feature-desc-cont">
                  <span className="mb-title mb-social-title">YouTube Channel 2</span>
                  <div className="mb-feature-desc">Various videos about the editor and game development</div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="mb-center">
              <div className="mb-center mb-network">
                <a href="https://github.com/v-pukman-gd">
                  <img src={githubImg} />
                </a>
                <div className="mb-feature-desc-cont">
                  <span className="mb-title mb-social-title">GitHub</span>
                  <div className="mb-feature-desc">Get source code of games created with the editor</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-offset" />

          {/* help */}
          <Row justify="center">
            <Col className="mb-center" xs={24}>
              <h1 className="mb-home-header" id="support">Support</h1>
            </Col>
          </Row>
          <Row justify="center" className="mb-support-cont">
            <Col className="mb-center" xs={24}>
              <div className="mb-center">
                <span className="mb-feature-desc mb-support-desc"> Do you have any question or want to send a feedback?</span>
                <Button onClick={() => actions.sendEmail()} className="mb-btn-email" type="primary" shape="round" icon={<MailOutlined />} size="large">
                  Send email
                </Button>
              </div>
            </Col>
          </Row>

        </Layout.Content>
      </Layout.Content>
    )
  }
}

export default RouterParams(Home);
