import React from "react"
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { AutoComplete, Input, Dropdown, Badge } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import actions from "../actions"
import update from 'immutability-helper'

class SearchBox extends React.PureComponent {
  static propTypes = {
    search: PropTypes.shape({
      name: PropTypes.string,
      artists: PropTypes.array,
      titles: PropTypes.array
    })
  }

  constructor(props) {
    super(props)
    this.inputRef = React.createRef();
  }

  componentDidMount(){
    if (this.inputRef.current) {
      this.inputRef.current.input.addEventListener("focus", this.onInputFocus);
    }
  }

  onInputFocus = (e) => {
    e.target.select()
  }

  componentWillUnmount(){
    if (this.inputRef.current) {
      this.inputRef.current.input.removeEventListener("focus", this.onInputFocus);
    }
  }

  render() {
    return (
      <AutoComplete
        style={{width: "100%"}}
        options={this.buildOptions()}
        onSelect={this.onSelectOption}
        onSearch={this.onInputChange}
      >
        <Input.Search
          ref={this.inputRef}
          //style={{padding: "0 10px 10px 0"}}
          placeholder="Search by artist or title"
          enterButton
          allowClear
          size="large"
          onSearch={this.onSearchPress} // triggers on button press, clear press and Enter press
         />

      </AutoComplete>
    )
  }

  buildOptions() {
    const { artists, titles } = this.props.search
    const options1 = artists.map((data) => ({data: data, value: data.artist, label: (<div><span style={{paddingRight: 5}}>{data.artist}</span><Badge count={data.count} color="#1890ff" /></div>)}))
    const options2 = titles.map((data) => ({data: data, value: [data.artist, data.title].join(" - "), label: (<div><span style={{paddingRight: 5}}>{[data.artist, data.title].join(" - ")}</span><Badge count={data.count} color="#1890ff" /></div>)}))
    return [...options1, ...options2]
  }

  onInputChange = (value) => {
    clearTimeout(this.timeout)

    if (value) {
      this.timeout = setTimeout(() => {
        actions.searchAudio({name: value})
      }, 1000)
    }
    else {
      actions.clearSearch();
      actions.filerAudios({artist: null, title: null, operator: 'and', page: 1});
    }
  }

  onSelectOption = (value, option) => {
    const { artist, title } = option.data
    actions.filerAudios({artist, title, operator: 'and', page: 1})
    this.inputRef.current.blur()
    return value;
  }

  onSearchPress = (value) => {
    if (value) {
      const valueArr = value.split(" - ")
      let artist, title, operator;
      if (valueArr.length >= 2) {
        artist = valueArr[0]
        title = valueArr[1]
        operator = 'and'
      }
      else {
        artist = value;
        title = value;
        operator = 'or'
      }
      actions.filerAudios({artist, title, operator, page: 1})
      clearTimeout(this.timeout)
    }
  }
}

export default SearchBox;
