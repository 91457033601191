import React from "react"
import { Radio, Space, List, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component';
import AudioItem from '../../library/components/audio_item'
import SubmitBtn from './submit_btn'
import CancelBtn from './cancel_btn'
import actions from '../actions'

class AudioSelectStep extends React.PureComponent {
  render() {
    const { audios, selectedAudioId, hasMore, loading, canceling } = this.props.uploader;

    return (
      <div>
        <div className="mb-audio-select-text">
          Select from existing audios or <a className="mb-cancel-select-btn" onClick={actions.selectAudioSkip}>upload new audio</a>
        </div>

         <InfiniteScroll
            dataLength={audios.length}
            next={this.onLoadMore}
            hasMore={hasMore}
            loader={
              <Skeleton
                avatar={{shape: "square", size: 128}}
                paragraph={{
                  rows: 2,
                }}
                active
              />
            }
            scrollableTarget="scrollable_container"
          >
            <List
              //itemLayout="vertical"
              //size="large"
              dataSource={audios}
              renderItem={(audio) =>
                <AudioItem audio={audio} className={selectedAudioId == audio.id ? "mb-audio-item-select-list mb-audio-item-selected" : "mb-audio-item-select-list"} checkbox={
                  <Radio key={audio.id} onClick={(e) => this.onClick(e) } checked={selectedAudioId == audio.id} value={audio.id}></Radio>
                } />
              }
            />
          </InfiniteScroll>

        <div className="mb-steps-action">
          <SubmitBtn disabled={false} onClick={actions.selectAudio} loading={loading} />
          <CancelBtn loading={loading} canceling={canceling} onClick={actions.cancel} />
        </div>
      </div>
    )
  }

  onClick = (e) => {
    //console.log("onClick", e.target.value)
    actions.selectAudioId(e.target.value)
  }

  onLoadMore = () => {
    const { filter } = this.props.uploader;
    actions.fetchAudios({...filter, page: filter.page + 1})
  }
}

export default AudioSelectStep;
