import {axios, API_URL} from './helpers/axios'

class Api {
  login(params) {
    return axios.post(`${API_URL}/login`, {
      user: {email: params.email, password: params.password, remember_me: params.remember_me}
    })
  }
  signup(params) {
    return axios.post(`${API_URL}/signup`, {
      user: {email: params.email, password: params.password}
    })
  }
  logout() {
    return axios.delete(`${API_URL}/logout`)
  }
  createOrder(params) {
    return axios.post(`${API_URL}/orders`, { product_id: params.productId })
  }
  fetchSubscriptionPlans(){
    return axios.get(`${API_URL}/products/subscriptions`)
  }
  fetchOrderStatus(params){
    return axios.get(`${API_URL}/orders/${params.orderId}`)
  }
  updateProfile(params) {
    return axios.post(`${API_URL}/users/update_profile`, params)
  }
  secureProfile(params) {
    return axios.post(`${API_URL}/users/secure_profile`, params)
  }
}
export default new Api();
