import React from "react"
import { Form, Input, Select, DatePicker, Button, Row, Col } from 'antd'
import SubmitBtn from './submit_btn'
import CancelBtn from './cancel_btn'
import actions from '../actions'
import AppContext from '../../components/app_context'
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment'

class AudioInfoStep extends React.PureComponent {
  static contextType = AppContext

  render() {
    const { loading, canceling, resource, audioInfo } = this.props.uploader;
    return (

      <div className="mb-audio-info-cont">
        <Form
          name="audio"
          labelCol={{ span: 6 }}
          wrapperCol={{span: 12 }}
          autoComplete="off"
          onFinish={this.onFinishForm}
          initialValues={{
            artist: audioInfo?.artist,
            title: audioInfo?.title,
            album: audioInfo?.album,
            genre: audioInfo?.genre,
            subgenre: audioInfo?.subgenre,
            date: this.parseDate(audioInfo?.date),
            download_link: audioInfo?.downloadLink,
            license: 'CC-BY',
            comments: audioInfo?.comments,
          }}
        >

          <Form.Item
            name="artist"
            label="Artist"
            rules={[
              {
                required: true,
                message: 'Artist is required',
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Title is required',
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="album" label="Album">
            <Input />
          </Form.Item>
          <Form.Item name="genre" label="Genre">
            <Select>
              {this.context.settings.audioGenres.reverse().map((genre) => <Select.Option key={genre} value={genre}>{genre}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item name="subgenre" label="Subgenre">
            <Input />
          </Form.Item>
          <Form.Item name="date" label="Release date">
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="download_link"
            label="Music store / Website link"
            rules={[
              {
                required: true,
                message: 'Link is required',
              },
              {
                type: 'url',
                warningOnly: true,
                message: 'Provided link is not valid url',
              }
            ]}
          >
            <Input placeholder="https://example.com" />
          </Form.Item>
          <Form.Item name="license" label="License" style={{marginBottom: 0}}
            rules={[
              {
                required: true,
                message: 'License is required',
              }
            ]}
          >
            <Select>
              {this.context.settings.audioLicenses.map((license) => <Select.Option key={license.id} value={license.id}>{license.label}</Select.Option>)}
            </Select>
          </Form.Item>
          <Row style={{marginBottom: 24}}>
            <Col xs={0} sm={6} md={6}>
            </Col>
            <Col xs={18} sm={12} md={12}>
              <div className="mb-cc-info"><InfoCircleOutlined style={{color: 'grey'}} /> More info about <a href="https://creativecommons.org/share-your-work/cclicenses/" target="_blank">Creative Commons licenses</a></div>
            </Col>
          </Row>
          <Form.Item name="comments" label="Comments">
            <Input.TextArea rows={2} />
          </Form.Item>

          <div className="mb-steps-action">
            {/* htmlType=submit submits the form, no onClick needed*/}
            <SubmitBtn htmlType="submit" loading={loading} />
            {resource == "map" && <CancelBtn loading={loading} canceling={canceling} onClick={actions.cancel} />}
          </div>
        </Form>
      </div>
    )
  }

  onFinishForm = (params) => {
    //console.log("onFinishForm: ", params)
    let date = params.date;
    if (date) { date = date.format("YYYY-MM-DD") } //TODO: apply dayjs after migration to version 5
    let formData = {...params, date: date}

    actions.createAudio(formData)
  }

  parseDate(value) {
    if (value == undefined || value == '') { return null; }

    let parsedValue = moment(value)
    if (parsedValue.isValid()) { return parsedValue; }
    else { return null; }
  }
}

export default AudioInfoStep;
