import React from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { toCamelCase } from './axios'

const RouterParams = WrappedComponent => props => {
  let params = useParams()
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchParamsObject = {};
  for(let [key, value] of searchParams.entries()) {
    searchParamsObject[key] = value;
  }

  return (
    <WrappedComponent
      {...props}
      routerParams={{params: params, searchParams: toCamelCase(searchParamsObject), navigate: navigate}}
    />
  );
};

export default RouterParams;
