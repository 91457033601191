import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { Layout, Typography, Button, Checkbox, Form, Input, Col, Row, Alert } from 'antd'
import { Navigate, Link } from "react-router-dom";
const { Title } = Typography;
import actions from '../actions'
import update from 'immutability-helper';

import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import validatePassword from '../helpers/validate_password'
const CAPTCHA_SIZE = 4

import AppContext from '../components/app_context'

class Signup extends React.PureComponent {
  static contextType = AppContext
  static propTypes = {
    errors: PropTypes.shape({
      loginError: PropTypes.string
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      captchaValid: true
    }

    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.errors.loginError) actions.clearError('loginError');
    this.initCaptcha()
  }

  render() {
    if (this.context.loginCompleted) { return <Navigate to="/profile" replace={true} /> }
    const { errors: {loginError} } = this.props;

    return (
      <Layout.Content id="mb-signup" className="mb-layout-content">
        <Row justify="center">
          <Col xs={24} sm={24} md={18} lg={18} xl={12} xxl={12} className="mb-content-col">
            <div className="mb-content">
              <Title level={4} className="mb-left">Register</Title>

              {loginError && <Alert message={loginError} type="error" />}

              <Form
                ref={this.formRef}
                name="signup"
                labelCol={{ span: 8 }}
                wrapperCol={{span: 16 }}
                initialValues={{remember: true}}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid email!',
                    },
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        const error = validatePassword(value)
                        if (error) { return Promise.reject(error); }
                        else { return Promise.resolve(); }
                      }
                    }
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The two passwords don't match!"));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>


                <Form.Item
                  name="captcha"
                  label="Code"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the code',
                    },
                    {
                      validator: (_, value) => {
                        if (this.state.captchaValid) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The code doesn't match!"));
                      }
                    }
                  ]}
                >
                  <Input className="mb-captcha-input" maxLength={CAPTCHA_SIZE} onChange={(vale) => this.setState(update(this.state, {captchaValid: {$set: true}})) } />
                </Form.Item>

                <Row>
                  <Col xs={24} className="mb-center">
                    <div className="mb-captcha">
                      <LoadCanvasTemplate reloadText="&#8634;" reloadColor="#1890ff" />
                    </div>
                  </Col>
                </Row>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    >
                    Register
                  </Button>
                  <span className="mb-form-link-pre"> or </span>
                  <Link to="/login" className="ant-btn ant-btn-link mb-link mb-form-link">Log in</Link>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <div className="mb-center mb-terms-accept">
                    By pressing <strong>Register</strong>, you agree to the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>
                  </div>
                </Form.Item>

              </Form>

            </div>
          </Col>
        </Row>
      </Layout.Content>
    )
  }

  initCaptcha() {
    loadCaptchaEnginge(CAPTCHA_SIZE, 'white', 'black', 'numbers');
  }

  onFinish = (params) => {
    //console.log('Success:', params);
    if (validateCaptcha(params.captcha)) {
      this.setState(update(this.state, {captchaValid: {$set: true}}))
      this.initCaptcha()
      this.formRef.current.setFieldValue('captcha', '')
      actions.signup(params)
    }
    else {
      this.setState(update(this.state, {captchaValid: {$set: false}}))
      this.formRef.current.validateFields()
    }
  }

  onFinishFailed(errorInfo) {
    //console.log('Failed:', errorInfo);
  }
}

export default Signup;
