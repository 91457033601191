import React from "react"
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import SubmitBtn from './submit_btn'
import CancelBtn from './cancel_btn'
import actions from '../actions'

class UploadAudioStep extends React.PureComponent {
  render() {
    const { resource, audio, audioFile, loading, canceling } = this.props.uploader;
    if (!audio) return;
    const isSubmitDisabled = (resource == "audio" && audioFile == null) // require audio file for audio uploader
    return (
      <div>
        <Upload.Dragger
          beforeUpload={this.onSelectAudioFile}
          accept=".mp3, .ogg"
          onRemove={this.onRemoveAudioFile}
          fileList={audioFile ? [audioFile] : []}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{audio.fullTitle}</p>
          <p className="ant-upload-text">Upload audio file with extension .mp3, .ogg</p>
          <p className="ant-upload-hint">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>

        <div className="mb-steps-action">
          <SubmitBtn disabled={isSubmitDisabled} onClick={this.onUploadAudioFile} loading={loading} />
          <CancelBtn loading={loading} canceling={canceling} onClick={actions.cancel} />
        </div>
      </div>
    )
  }

  onSelectAudioFile = (file) => {
    //console.log("onSelectAudioFile: ", file)
    //TODO: validate by extension and size
    if (!file) { return false; }
    actions.selectAudioFile(file)
    return false; // important to return false
  }

  onRemoveAudioFile = () => {
    //console.log("onRemoveAudioFile: ")
    actions.selectAudioFile(null)
  }

  onUploadAudioFile = () => {
    //console.log("onUploadAudioFile: ", this.props.uploader.audioFile)

    const {resource, audio, audioFile} = this.props.uploader;
    if (resource == "map" && audioFile == null) {
      actions.uploadAudioSkip();
      return;
    }

    const data = new FormData();
    data.append('file', audioFile)
    actions.uploadAudio(audio.id, data)
  }
}

export default UploadAudioStep;
