import React from "react"
import { Button, Popconfirm } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons';

class CancelBtn extends React.PureComponent {
  render() {
    const { loading, canceling, onClick } = this.props;
    const disabled = loading || canceling;
    return (
      <>
      <span className="mb-form-link-pre"> or </span>
      <Popconfirm
        title="The entire upload session will be removed. Are you sure?"
        onConfirm={onClick}
        disabled={disabled}
        okText="Yes, remove"
        cancelText="No"
      >
        <Button type="link" disabled={disabled}>
          Cancel
        </Button>
      </Popconfirm>
      </>
    )
  }
}

export default CancelBtn;
