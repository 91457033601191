import _axios from 'axios'
import toCamelCase from "camelcase-object-deep"
import toSnakeCase from "snakecase-keys"
import { clearData } from './local_storage'

const API_URL = '/api/v1'

const axios = _axios.create()
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "X-MBOY-Token";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

const handleResponseError = (error) => {
  if (error.response?.status == 401 && error.response?.config?.url != `${API_URL}/login`) {
    clearData()
    window.document.location.href = '/login'
    error.handled = true
  }
  else { return Promise.reject(error) }
}

const handleResponse = (response) => {
  return {
    ...response,
    data: toCamelCase(response.data, { deep: true })
  }
}

axios.interceptors.response.use(handleResponse, handleResponseError)

export {axios, API_URL, toCamelCase, toSnakeCase};
